import Button from '@mui/material/Button';
function ButtonMain({
  onPress,
  text,
  font,
  color,
}: {
  onPress: any;
  text: string;
  font: string;
  color: any;
}) {
  return (
    <div className="w-full px-3 py-2 ">
      <Button
        sx={{ fontWeight: font, backgroundColor: color ? color : '#4CBFA7' }}
        onClick={onPress}
        className="w-full"
        variant="contained"
      >
        {text}
      </Button>
    </div>
  );
}

export default ButtonMain;
