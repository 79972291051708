import { Loader } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { startLoading } from 'store/Slice/loadingSlice';
import { loginwithToken } from 'store/Thunk/authThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import useAuthRedirectHandler from '../../hooks/useAuthRedirectHandler';
interface Props {
  children: JSX.Element;
  path?: string;
}

const AuthRoute = ({ children, path }: Props) => {
  const userData: any = localStorage.getItem('userData');
  const isAuthenticated = JSON.parse(userData);

  console.log('isAuthenticated', isAuthenticated);

  const { user } = useAppSelector((state: any) => state.authReducer);

  const { loginUser } = useAuthRedirectHandler();

  const dispatch: any = useAppDispatch();

  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const redirect = searchParams.get('redirectTo');

  useEffect(() => {
    const login = async () => {
      if (searchParams.get('token')) {
        try {
          dispatch(startLoading());
          setIsLoading(true);
          localStorage.clear();
          await dispatch(
            loginwithToken({ token: searchParams.get('token') }),
            navigate,
          );
          const token = searchParams.get('token');
          if (token) {
            localStorage.setItem('token', token);
          }
          searchParams.delete('token');
          if (redirect) {
            searchParams.delete('redirectTo');
            navigate(redirect?.[0] === '/' ? redirect : `/${redirect}`);
          } else {
            console.log('pathname', pathname);
            navigate(pathname);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    };
    if (isAuthenticated && isAuthenticated?.token) {
      loginUser(isAuthenticated, isAuthenticated.token);
    } else {
      navigate('/login');
    }
  }, [searchParams]);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isLoading]);

  // if (!Object.keys(isAuthenticated ? isAuthenticated : {}).length) {
  //   return <Navigate to={'/login'} replace />
  // }

  // const location = useLocation();
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const token: any = new URLSearchParams(location.search).get("token");
  // const userId: any = new URLSearchParams(location.search).get("useridb64");

  // useEffect(() => {
  //   if (location.pathname == "/reset-password") {
  //     let _request: any = {
  //       token: token,
  //       userId: userId,
  //     };
  //     dispatch(verifyResetPasswordToken({ _request, navigate }));
  //   }
  // },[token, userId])
  /*   if (isAuthenticated && isAuthenticated?.token) {
    const userRole = isAuthenticated['type'];
    if (userRole === Roles.ADMIN) {
      return <Navigate to={'/super-admin/dashboard'} replace />;
    } else if (userRole === Roles.TEACHER) {
      return <Navigate to={'/teacher/dashboard'} replace />;
    } else if (userRole === Roles.SCHOOL_LEADER) {
      return <Navigate to={'/school-leader/dashboard'} replace />;
    } else if (userRole === Roles.PARENT) {
      return <Navigate to={'/parent/dashboard'} replace />;
    } else {
      return <Navigate to={'/student/dashboard'} replace />;
    }
  } */
  return (
    <>
      {children}

      {isLoading && (
        <div className="fixed inset-0 bg-white bg-opacity-50 z-50 flex justify-center items-center">
          <Loader size="md" color="pink" />
          <h2 className="text-pink-500 font-bold text-lg">Please wait...</h2>
        </div>
      )}
    </>
  );
};

export default AuthRoute;
