import WelcomeText from './WelcomeText';
import { Typography, Box } from '@mui/material';
import VerifiedButton from './VerifiedButton';
function Header({
  name,
  data,
  role,
  onPress
}: {
  name?: any;
  data?: any;
  role: string;
  onPress?:any
}) {
  return (
    <Box
      position="relative"
      borderRadius="xl"
      sx={{
        overflow: 'hidden',
      }}
    >
      <div className="flex items-center justify-between">
        <div className="ml-3 mt-2 block md:flex lg:flex justify-start  items-center">
          <WelcomeText role={role} name={name} />

          <div className="w-fit">
            <VerifiedButton role={role} />
          </div>
        </div>
        {!data?.isUserUpgradedPlan ? (
          <div
            role={'button'}
            onClick={onPress}
            className="bg-[#20A58A] h-[45px] lg:w-[18%] w-[35%] flex items-center justify-center mr-6 mt-3 rounded-xl text-[white] font-semibold lg:text-[15px] text-[12px]"
          >
            Upgrade Plan
          </div>
        ) : null}
      </div>
<div className='flex mt-3 '>
      <Typography
        sx={{ marginLeft: 4,   }}
        color="#84818A"
        variant="caption"
        gutterBottom
        
      >
        {role == 'parentNotOnboarded'
          ? 'Learn what matters. Earn what counts.'
          : role == 'parentOnboarded' || role == 'parentUpgraded'
          ? 'Here’s what [Child’s Name] is achieving across G3MS Classrooms and Mobile App.'
          : role == 'EduNoOnbrded' ||
            role == 'EduOnboarded' ||
            role === 'eduWithData'
          ? 'Ready to personalize learning for your students? Complete your setup below to unlock the full power of G3MS.'
          : ''}
      </Typography>
      </div>
    </Box>
    
  );
}

export default Header;
