import config from 'config/config';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyGetMyProfileWithTokenQuery } from 'store/apis/profile';
import { TMyProfile } from 'store/apis/profile/types';

export default function useAuthRedirectHandler() {
  const [getUserData, isUserDataLoading] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLazyGetMyProfileWithTokenQuery();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirectTo');

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const loginUser = async (
    userData: TMyProfile | undefined,
    token: string,
    query: Record<string, string> = {},
  ) => {
    console.log('userData', userData, token, query);

    let user = userData;

    if (!user && !isUserDataLoading) {
      const response = await getUserData();
      user = response.data?.data;
    }

    if (!user) {
      return;
    }

    const queryParams = queryString.stringify(query);

    const redirectTo = redirect ? `/${redirect}` : query?.redirectTo || null;

    switch (user.type) {
      case 'student':
        window.location.href = `${config.STUDENT_BASE_URL}/auth/token-login?token=${token}&${queryParams}`;
        break;
      case 'teacher':
        navigate(
          redirectTo ||
            `/school/${user.school.id}?token=${token}&${queryParams}`,
        );
        // window.location.href = `${config.ADMIN_URL}/school/${user.school.id}?token=${token}`;
        break;
      case 'parent':
        navigate(
          redirectTo || `/parent/dashboard?token=${token}&${queryParams}`,
        );
        break;
      case 'school_leader':
      case 'learning_coach':
        console.log(`/district-leader/dashboard?token=${token}&${queryParams}`);
        navigate(
          redirectTo ||
            `/district-leader/dashboard?token=${token}&${queryParams}`,
        );
        break;
      default:
        // window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?${queryParams}`;
        navigate(
          redirectTo ||
            `/district-leader/dashboard?token=${token}&${queryParams}`,
        );

        break;
    }
  };

  return { loginUser };
}
