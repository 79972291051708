import { Box, Typography } from '@mui/material';
import React from 'react';
import Header from './Header';

function ProductsEngagement() {
  return (
    <Box className="lg:w-[43%] border border-[#EAECF0] mt-2 rounded-lg p-2">
      <Header text={'Engagement by products'} />
      <Typography
        sx={{ fontWeight: 400, fontSize: 12, marginTop: 3 }}
        color={'#072D20'}
      >
        Gen Alpha & Z are mobile-first generations!
      </Typography>
      <Typography
        sx={{ fontWeight: 400, fontSize: 12, marginTop: 3 }}
        color={'#072D20'}
      >
        📱 See how G3MS tracks and optimizes their favorite devices for learning
        success. 📊
      </Typography>
      <div className="flex items-center justify-center mt-7">
        <img
          src={require('../../../../assets/Parent/G3MSforClassrooms.png')}
          className="h-20 w-20"
        />
      </div>
    </Box>
  );
}

export default ProductsEngagement;
