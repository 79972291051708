import CustomButton from 'components/CustomButton';

import Modal from 'components/Modal/components/Modal';
import useModal from 'components/Modal/hook/useModal';
import Logo from 'components/NewLogo';
import { TWarnModalProps } from './types';

export default function WarningModal() {
  const { closeModal, isOpen, modal } = useModal<TWarnModalProps>();

  return (
    <Modal
      isOpen={isOpen('WarningModal')}
      onClose={() => {
        closeModal();
        modal?.props?.onClose?.();
      }}
    >
      <Modal.Content className="[--max-width:_30rem]">
        <Modal.CloseButton />
        <div className="flex flex-col gap-8 py-4">
          <Logo className="mx-auto" />

          <h1 className="text-[#131515] font-semibold text-lg md:text-xl xl:text-2xl text-center mx-auto">
            {modal?.props?.title}
          </h1>

          {modal?.props?.description && (
            <p className="text-sm md:text-base lg:text-lg  2xl:text-xl text-black text-center mx-auto max-w-[45ch]">
              {modal?.props?.description}
            </p>
          )}

          <CustomButton
            className="max-w-none w-full"
            variant="primary"
            onClick={() => {
              closeModal();
              modal?.props?.onContinue?.();
            }}
          >
            {modal?.props?.buttonText || 'Continue'}
          </CustomButton>
        </div>
      </Modal.Content>
    </Modal>
  );
}
