import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';

import CustomizedCheckboxAcademic from '../CheckBoxes/CustomizedCheckboxAcademic';
import ButtonMain from '../ButtonMain';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputComponent from '../Educator/InputComponent';
import { RadioButtonChecked } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function EducatorSettings({
  onClose,
  open,
  onPress,
}: {
  onClose: any;
  open: any;
  onPress: any;
}) {
  const [view, setView] = React.useState(1);
  const [nextView, setNextView] = React.useState(0);
  const [value, setValue] = React.useState('female');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const heading =
    view == 1 ? 'School Settings' : view == 2 ? 'Upload Data' : '';
  const uploads = [
    {
      text: 'Would you like to upload your instructional calendar?',
    },
    {
      text: ' Would you like to upload your student data?',
    },
    {
      text: 'Would you like to upload your teacher data?',
    },
    {
      text: ' Would you like to upload your classroom data?',
    },
    {
      text: ' Would you like to upload your student group ',
    },
  ];
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="innerboxShadow "
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '55%',
            //width: 700,
            padding: 3,
            backgroundColor: '#fff',
            boxShadow: 'shadow-inner-2xl',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <div
            onClick={onClose}
            className=" flex md:flex lg:flex justify-end pr-4 pt-4"
          >
            <img
              src={require('../../../../assets/Parent/cross.png')}
              style={{ height: 8, width: 8 }}
            />
          </div>

          <div className="mx-3">
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 700,
                //textAlign: 'center',
              }}
              color={'#2C3E50'}
            >
              {heading}
            </Typography>
          </div>

          {view == 1 ? (
            <div className="w-full px-1">
              <InputComponent
                label3=""
                label4=""
                fourBlock={false}
                more={false}
                addMore={''}
                helpingText={
                  'Let’s get your School Settings configured to streamline personalized learning and performance tracking.'
                }
                heading={'School Type'}
                heading2={'Grades/Subjects'}
                label={'Select type'}
                label2={'Select'}
              />
              <InputComponent
                label3=""
                label4=""
                fourBlock={false}
                more={true}
                addMore={() => {
                  if (nextView >= 2) {
                    setNextView(nextView + 1);
                  }
                }}
                helpingText={
                  'Leadership Information School Leader (Add up to 3 additional leaders)'
                }
                heading={'First Name'}
                heading2={'Last Name'}
                label={'Type first Name...'}
                label2={'Type last name...'}
              />
              {nextView == 1 || nextView == 2 ? (
                <InputComponent
                  label3=""
                  label4=""
                  fourBlock={false}
                  more={false}
                  addMore={false}
                  helpingText={
                    'Leadership Information School Leader (Add up to 3 additional leaders)'
                  }
                  heading={'First Name'}
                  heading2={'Last Name'}
                  label={'Type first Name...'}
                  label2={'Type last name...'}
                />
              ) : null}
              {nextView == 2 ? (
                <InputComponent
                  label3=""
                  label4=""
                  fourBlock={false}
                  more={false}
                  addMore={false}
                  helpingText={
                    'Leadership Information School Leader (Add up to 3 additional leaders)'
                  }
                  heading={'First Name'}
                  heading2={'Last Name'}
                  label={'Type first Name...'}
                  label2={'Type last name...'}
                />
              ) : null}
              <InputComponent
                label3=""
                label4=""
                fourBlock={false}
                more={false}
                addMore={''}
                helpingText={'Teacher & Classroom Information'}
                heading={'Number of teachers'}
                heading2={'School Performance'}
                label={'Add number of teachers...'}
                label2={'Type school performance...'}
              />
              <InputComponent
                fourBlock={true}
                more={false}
                addMore={''}
                helpingText={
                  'Provide starting and ending percentages for student proficiency levels'
                }
                heading={'Exceeding:'}
                heading2={'Meeting:'}
                label={'Start %'}
                label2={'Start %'}
                label3="End %"
                label4="End %"
              />
              <InputComponent
                fourBlock={true}
                more={false}
                addMore={''}
                helpingText={''}
                heading={'Approaching:'}
                heading2={'Emerging:'}
                label={'Start %'}
                label2={'Start %'}
                label3="End %"
                label4="End %"
              />
              <div className="flex items-center mt-4 ml-3">
                <Typography
                  sx={{
                    fontSize: 13,
                    fontWeight: 400,
                    fontFamily: 'inter',
                  }}
                  color={'#000000'}
                >
                  Does your school have an RTI (Response to Intervention) plan?
                </Typography>
                <RadioGroup
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '12px',
                      color: '#484848',
                    },
                    marginLeft: 3,
                  }}
                  value={value}
                  onChange={handleChange}
                >
                  <div className="flex">
                    <FormControlLabel
                      value="female"
                      control={
                        <Radio
                          icon={<RadioButtonChecked></RadioButtonChecked>}
                          sx={{
                            color: '#484848',
                            fontSize: 11,
                            fontWeight: 500,
                          }}
                          color="secondary"
                          size="small"
                        />
                      }
                      label="Yes"
                    />
                    <Button className="w-20" variant="outlined">
                      Upload
                    </Button>
                    <FormControlLabel
                      value="male"
                      control={
                        <Radio
                          icon={<RadioButtonChecked></RadioButtonChecked>}
                          sx={{
                            color: '#484848',
                            fontSize: 11,
                            fontWeight: 500,
                            marginLeft: 3,
                          }}
                          color="secondary"
                          size="small"
                        />
                      }
                      label="No"
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>
          ) : view == 2 ? (
            uploads.map((item) => (
              <div className="flex items-center justify-between mt-4 px-3">
                <Typography
                  sx={{
                    fontSize: 13,
                    fontWeight: 400,
                    fontFamily: 'inter',
                  }}
                  color={'#000000'}
                >
                  {item.text}
                </Typography>
                <Button className="w-64" variant="outlined">
                  Upload Calendar
                </Button>
              </div>
            ))
          ) : null}
          <div className="flex justify-center items-center mt-4 mb-3">
            <Button
              sx={{ fontWeight: 400, backgroundColor: '#C73E9D' }}
              onClick={
                view < 2
                  ? () => {
                      if (view < 2) {
                        setView(view + 1);
                      }
                    }
                  : onPress
              }
              className="w-72"
              variant="contained"
            >
              {view === 1 ? 'Submit' : 'Next'}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default EducatorSettings;
