import React from 'react';
import SkillBox from './SkillBox';
function SkillsMain({ data }: { data: any }) {
  console.log('totals from component', data);
  return (
    <div className="lg:flex md:flex grid grid-cols-3 items-center lg:space-x-1 lg:space-y-0  mt-2">
      <SkillBox
        role={data}
        img={require('../../../../assets/Parent/clock.png')}
        text={'Skills Mastered'}
        values={data?.userMasteredSkills?.isMastered || 0}
      />
      <SkillBox
        role={data}
        img={require('../../../../assets/Parent/calendar-day.png')}
        text={'Daily Practice'}
        values={data?.dailyPracticesPercentage || 0}
      />
      <SkillBox
        role={data}
        img={require('../../../../assets/Parent/alarm-clock.png')}
        text={'Bellwork/DoNOW'}
        values={data?.trackedResults?.schoolAssignmentBellworkPercentage || 0}
      />
      <SkillBox
        role={data}
        img={require('../../../../assets/Parent/ticket-airline.png')}
        text={'Exit tickets'}
        values={data?.trackedResults?.schoolAssignmentExitTicketPercentage || 0}
      />
      <SkillBox
        role={data}
        img={require('../../../../assets/Parent/quiz.png')}
        text={'Unit Quizzes'}
        values={data?.trackedResults?.schoolAssignmentUnitQuizPercentage || 0}
      />
      <SkillBox
        role={data}
        img={require('../../../../assets/Parent/unit-test.png')}
        text={'Unit Test'}
        values={data?.trackedResults?.schoolAssignmentUnitTestPercentage || 0}
      />
      <SkillBox
        role={data}
        img={require('../../../../assets/Parent/unit-test.png')}
        text={'Diagnostic'}
        values={data?.trackedResults?.schoolAssignmentDiagnosticPercentage || 0}
      />
    </div>
  );
}
export default SkillsMain;
