import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useModal from 'components/Modal/hook/useModal';
import AddStudentComponent from 'pages/parent/components/AddStudent/AddStudentComponent';
import ButtonMain from 'pages/parent/components/ButtonMain';
import BenifitsText from 'pages/parent/components/FreePlan/BenifitsText';
import Progresss from 'pages/parent/components/Progress/Progresss';
import * as React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function ParentFreePlanStep2() {
  const { closeModal, openModal, isOpen } = useModal();

  const val = 66;
  const text = '2 / 3';

  const heading = 'Add a student?';

  return (
    <div>
      <Modal
        open={isOpen('ParentFreePlanStep2')}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="innerboxShadow w-96"
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: view == 3 ? 380 : view == 6 ? 300 : 440,
            //width: 700,
            padding: 3,
            backgroundColor: '#fff',
            boxShadow: 'shadow-inner-2xl',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <div
            onClick={closeModal}
            className=" flex md:flex lg:flex justify-end pr-4 pt-4"
          >
            <img src="/images/cross.png" style={{ height: 8, width: 8 }} />
          </div>

          <div className={' flex mt-3 ml-4'}>
            <div>
              <Progresss
                value={val}
                style={{ height: 50, width: 50 }}
                text={text}
                stroke="#C73E9D"
              />
            </div>
            <div className="mx-3">
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 700,
                  //textAlign: 'center',
                }}
                color={'#2C3E50'}
              >
                {heading}
              </Typography>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 11,
                  //textAlign: 'center',
                  marginTop: 1,
                  //paddingX: view == 6 ? 4 : 2,
                }}
                color={'#000000'}
              >
                <div className={'mt-3'}></div>
              </Typography>
            </div>
          </div>

          <AddStudentComponent type="student" />

          <div className="mt-5">
            <ButtonMain
              color="#C73E9D"
              font={'400'}
              onPress={() => {
                openModal('ParentFreePlanStep3');
              }}
              text={'Add Student'}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ParentFreePlanStep2;
