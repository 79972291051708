import { Box, Typography } from '@mui/material';
//import { student } from 'assets';
import * as React from 'react';
//import { setStudent } from 'store/Slice/studentSlice';
import AcademicUnlock from './components/AcademicUnlock';
import EngagementbyProducts from './components/EngagementbyProducts';
import EngagementbyTopics from './components/EngagementbyTopics';
import Header from './components/Header';
import LeaderRewards from './components/LeaderRewards';
import ProductsEngagement from './components/ProductsEngagement';
//import SkillBox from './components/SkillBox';
import SkillsMain from './components/SkillsMain';
//import SocialMatrics from './components/SocialMatrics';
import SocialMatricsList from './components/SocialMatricsList';
import Strengths from './components/Strengths';
import StudentList from './components/StudentList';
import SubjectSelection from './components/SubjectSelection';
import ToDoList from './components/ToDoList';
import TopicsEngamement from './components/TopicsEngamement';
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import { ThunkDispatch } from '@reduxjs/toolkit';
import {
  getChildList,
  getChildTotals,
  getParentSubTopicList,
} from 'store/Thunk/ParentDashboardThunk';
import { useAppSelector } from 'store/store';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ToDo from './components/ToDo';
import { useGetMyProfileQuery } from 'store/apis/profile';

function ParentOnboarded({ onClick, view }: { onClick: any; view: string }) {
  const [selected, setSelected] = React.useState('MATH01');
  const {
    childList,
    childTotals,
    childReactions,
    childEngagements,
    parentSubtopicList,
    studentInfo,
    studentQRdata,
  } = useAppSelector((state: any) => state.parentDashboardReducer);
  //const [studentData, setStudentData]=React.useState({})
  const [open, setOpen] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(10);
  const [pageNo, setPageNo] = React.useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //  const [selectedStudent, setSelectedStudent] = React.useState(1);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { data } = useGetMyProfileQuery();
  React.useEffect(() => {
    dispatch(getChildList());
    dispatch(
      getParentSubTopicList({
        limit: pageCount,
        page: pageNo,
        topicId: 'MATH01',
      }),
    );
  }, []);

  React.useEffect(() => {
    dispatch(
      getParentSubTopicList({
        limit: pageCount,
        page: pageNo,
        topicId: 'MATH01',
      }),
    );
  }, [totalCount, pageNo]);
  //const [role, setRole] = React.useState('upgradedPrent');
  const data1 = [
    {
      id: 1,
      img: require('../../../assets/Parent/Vector.png'),
      text: 'Start the scheduled Bellwork/DoNOW.',
    },
    {
      id: 2,
      img: require('../../../assets/Parent/Vector2.png'),
      text: 'Start the scheduled Bellwork/DoNOW.',
    },
    {
      id: 3,
      img: require('../../../assets/Parent/Vector3.png'),
      text: 'Assign your students first tasks.',
    },
  ];

  const defaultCellStyle = {};
  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'Skill',
      accessor: 'skill',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'Topic',
      accessor: 'topic',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
  ];
  const totalAssesments = [
    {
      name: 'Mobile',
      value: childEngagements?.mobileView,
    },
    {
      name: 'Classroom',
      value: childEngagements?.webView,
    },
  ];
  const fetchData = () => {
    setPageNo(0);
    setPageCount(10);
  };

  const rows = React.useMemo(() => {
    if (parentSubtopicList?.length > 0) {
      const data = parentSubtopicList?.map((item: any) => ({
        id: item?.id,
        name: item?.name, 
        skill: item?.skill[0]?.skill?.name,
        topic: item?.topic[0]?.topic?.name,
      }));
      return data;
    } else return [];
  }, [parentSubtopicList]);
  // React.useEffect(() => {
  //   if (parentSubtopicList?.length > 0) {
  //     setPageCount(parentSubtopicList?.meta?.totalPages);
  //     setTotalCount(parentSubtopicList?.meta?.totalCount);
  //   }
  // }, [parentSubtopicList]);
  React.useEffect(() => {
    dispatch(getChildTotals(selected));
  }, [selected]);
  return (
    <div>
      {view == 'parentOnboarded' ? (
        <div className="block lg:flex lg:mx-0 justify-between mt-2 mx-3">
          {/** Left view */}
          <div className="w-full lg:w-[20%]">

            {childList?.map((item: any) => (
              <StudentList
                role={'student'}
                list={item?.dependentOfUser}
                addNew={() => console.log('add new')}
              />
            ))}
       
     
        
            {/* <ToDo data={data} />
            <LeaderRewards role={view} /> */}
          </div>
          {/** right view */}
          <div className="w-full lg:w-[80%] ">
            <Box className="border border-[#EAECF0] lg:mx-2 rounded-lg p-2">
              <div className="flex justify-between">
                <Header text={'Academic Metrics:'} />
                <SubjectSelection
                  selected={selected}
                  onClickMath={() => setSelected('MATH01')}
                  onClickELA={() => setSelected('ela')}
                  onClickScience={() => setSelected('science')}
                  onClickTest={() => setSelected('test')}
                />
              </div>
              <SkillsMain data={childTotals} />
              <Box className="border border-[#EAECF0] mt-2 rounded-lg p-2">
                {childTotals?.userStrenths?.length > 0 ||
                childTotals?.userGrowth?.length > 0 ? (
                  <div className="block lg:flex justify-between lg:space-x-3">
                    <div className="lg:w-1/2">
                      {childTotals?.userStrenths?.length > 0 ? (
                        <Header text="Strengths" />
                      ) : null}

                      <div className="mt-2">
                        {childTotals?.userStrenths?.map((item: any) => (
                          <Strengths
                            color={''}
                            Bcolor={''}
                            strength={true}
                            count={1}
                            text={item?.skill?.template[0]?.template?.title}
                            value={item?.score}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="lg:w-1/2 ">
                      {childTotals?.userGrowth?.length > 0 ? (
                        <Header text="Opportunities for Growth" />
                      ) : null}

                      <div className="mt-2">
                        {childTotals?.userGrowth?.map((item: any) => (
                          <Strengths
                            color={item?.score <= '68%' ? '#FFF0F0' : '#FEDF89'}
                            Bcolor={
                              item?.score <= '68%' ? '#FF6B6B' : '#FFFAEB'
                            }
                            strength={false}
                            count={1}
                            text={item?.skill?.template[0]?.template?.title}
                            value={item?.score}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <AcademicUnlock 
                  onClick={handleOpen}
                  />
                )}
              </Box>
              <SocialMatricsList data={childReactions} role={view} />
            </Box>

            {childEngagements?.byTopics?.length>0 ? (
              <div className="lg:flex block lg:mx-2 mb-2">
                <EngagementbyTopics data={childEngagements} />
                <EngagementbyProducts data={totalAssesments} />
              </div>
            ) : (
              <div className="lg:flex block lg:mx-2 mb-2">
                <TopicsEngamement
                  studentQR={studentQRdata?.qr}
                  studentData={studentInfo || null}
                  parent={true}
                  onClick={onClick}
                />
                <ProductsEngagement />
              </div>
            )}
          </div>
          <div>
            <Modal
              className="fixed bg-[white] left-0 top-0 z-[1055]  h-full w-full overflow-y-auto overflow-x-hidden  justify-center "
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className=" bg-[white] w-full ">
                <div className="ml-3 mt-3" role="button" onClick={handleClose}>
                  <ArrowBackIcon fontSize="small" />
                </div>

                <div className=" bg-[white] w-full flex justify-center ">
                  <div className="w-[90%]">
                    <CustomTable
                      fetchData={fetchData}
                      pageCount={pageCount}
                      pageNo={pageNo}
                      totalCount={totalCount}
                      table={{
                        columns: columns,
                        rows: rows,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      ) : //  view == 'parentUpgraded' ? (
      //   <div className="block lg:flex justify-between mt-2 ">
      //     <div className="w-full lg:w-[30%]">
      //       <StudentList
      //         list={students}
      //         addNew={() => console.log('add new')}
      //       />
      //       <ToDoList list={data} />
      //       <LeaderRewards role={role} />
      //     </div>
      //     <div className="w-full lg:w-[70%] ">
      //       <Box className="border border-[#EAECF0] lg:mx-2 rounded-lg p-2">
      //         <div className="flex justify-between">
      //           <Header text={'Academic Metrics:'} />
      //           <SubjectSelection
      //             selected={selected}
      //             onClickMath={() => setSelected('math')}
      //             onClickELA={() => setSelected('ela')}
      //             onClickScience={() => setSelected('science')}
      //             onClickTest={() => setSelected('test')}
      //           />
      //         </div>
      //         <SkillsMain role={role} />
      //         <Box className="border border-[#EAECF0] mt-2 rounded-lg p-2">
      //           <div className="flex items-center justify-between space-x-3">
      //             <div className="w-1/2">
      //               <Header text="Strengths" />
      //               <div className="mt-2">
      //                 <Strengths
      //                   color={''}
      //                   Bcolor={''}
      //                   strength={true}
      //                   count={1}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={''}
      //                   Bcolor={''}
      //                   strength={true}
      //                   count={2}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={''}
      //                   Bcolor={''}
      //                   strength={true}
      //                   count={3}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={''}
      //                   Bcolor={''}
      //                   strength={true}
      //                   count={4}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //               </div>
      //             </div>
      //             <div className="w-1/2">
      //               <Header text="Opportunities for Growth" />
      //               <div className="mt-2">
      //                 <Strengths
      //                   color={'#FFF0F0'}
      //                   Bcolor={'#FF6B6B'}
      //                   strength={false}
      //                   count={1}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={'#FFF0F0'}
      //                   Bcolor={'#FF6B6B'}
      //                   strength={false}
      //                   count={2}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={'#FFFAEB'}
      //                   Bcolor={'#FEDF89'}
      //                   strength={false}
      //                   count={3}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={'#FFFAEB'}
      //                   Bcolor={'#FEDF89'}
      //                   strength={false}
      //                   count={4}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //               </div>
      //             </div>
      //           </div>
      //         </Box>
      //         <SocialMatricsList role={role} />
      //       </Box>
      //       <div className="flex mt-3 mb-2 ml-2">
      //         <EngagementbyTopics />
      //         <EngagementbyProducts />
      //       </div>
      //     </div>
      //   </div>
      // ) :
      null}
    </div>
  );
}

export default ParentOnboarded;
