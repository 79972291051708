import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import CustomizedCheckbox from './CustomizedCheckbox';
import { typography } from '@mui/system';

export default function CustomizedCheckboxAcademic() {
  const [state, setState] = React.useState({
    math: false,
    ela: false,
    science: false,
    isee: false,
    shsat: false,
    ssat: false,
    psat: false,
    act: false,
    digitalSAT: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { math, ela, science, isee, shsat, ssat, psat, act, digitalSAT } =
    state;

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup sx={{ display: 'flex' }}>
          <div className=" grid  grid-cols-3 pl-4">
            <FormControlLabel
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={math}
                  onChange={handleChange}
                  name="math"
                />
              }
              label={
                <span style={{ fontSize: 13, fontWeight: 500 }}>Math</span>
              }
            />
            <FormControlLabel
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={ela}
                  onChange={handleChange}
                  name="ela"
                />
              }
              label={<span style={{ fontSize: 13, fontWeight: 500 }}>ELA</span>}
            />

            <FormControlLabel
              className=""
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={science}
                  onChange={handleChange}
                  name="science"
                />
              }
              label={
                <span style={{ fontSize: 13, fontWeight: 500 }}>Science</span>
              }
            />
            <FormControlLabel
              className="w-1/2"
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={isee}
                  onChange={handleChange}
                  name="isee"
                />
              }
              label={
                <span style={{ fontSize: 13, fontWeight: 500 }}>ISEE</span>
              }
            />
            <FormControlLabel
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={shsat}
                  onChange={handleChange}
                  name="shsat"
                />
              }
              label={
                <span style={{ fontSize: 13, fontWeight: 500 }}>SHSAT</span>
              }
            />

            <FormControlLabel
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={ssat}
                  onChange={handleChange}
                  name="ssat"
                />
              }
              label={
                <span style={{ fontSize: 13, fontWeight: 500 }}>SSAT</span>
              }
            />
            <FormControlLabel
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={psat}
                  onChange={handleChange}
                  name="psat"
                />
              }
              label={
                <span style={{ fontSize: 13, fontWeight: 500 }}>PSAT</span>
              }
            />
            <FormControlLabel
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={act}
                  onChange={handleChange}
                  name="act"
                />
              }
              label={<span style={{ fontSize: 13, fontWeight: 500 }}>ACT</span>}
            />
            <FormControlLabel
              control={
                <CustomizedCheckbox
                  size="small"
                  checked={digitalSAT}
                  onChange={handleChange}
                  name="digitalSAT"
                />
              }
              label={
                <span style={{ fontSize: 12, fontWeight: 500, width: '40%' }}>
                  Digital SAT
                </span>
              }
            />
          </div>
        </FormGroup>
      </FormControl>
    </Box>
  );
}
