import { Box } from '@mui/material';
import React from 'react';
import Header from './Header';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Line,
  Label,
  Legend,
} from 'recharts';
import { ReactI18NextChild } from 'react-i18next';
const data = [
  { name: 'Exceeding', value: 400 },
  { name: 'Meeting', value: 300 },
  { name: 'Approaching', value: 300 },
  { name: 'Emerging', value: 200 },
];
const COLORS = ['#3498DB', '#20A58A', '#FFD700', '#FF6B6B'];
const style = {
  top: '40%',
  right: 0,
  transform: 'translate(-10%, -50%)',
  lineHeight: '16px',
  color: 'black',
  fontSize: '11px',
};

const renderLegend = (props: any) => {
  const { payload } = props;

  return (
    <ul>
      {payload.map((entry: { value: any }, index: any) => (
        <li key={`item-${index}`}>{entry.value}</li>
      ))}
    </ul>
  );
};
const renderColorfulLegendText = (value: string, entry: any) => {
  const { color } = { color: 'black' };

  return <span style={{ color }}>{value}</span>;
};
function CustomLabel(value1: any, value2: any) {
  return (
    <text
      x="40%"
      y="40%"
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan alignmentBaseline="middle" fontSize="26">
        {value1}
      </tspan>
      <tspan fontSize="14">{value2}</tspan>
    </text>
  );
}
function ProficiencyLevels() {
  return (
    <Box className="border border-[#EAECF0] rounded-lg p-2 mx-1">
      <Header text="Growth levels + Attendance" />
      <div className="mt-2 mb-2">
        <PieChart width={300} height={150}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            innerRadius={40}
            outerRadius={60}
            cx="40%"
            cy="60%"
            fill="#8884d8"
            paddingAngle={-15}
            blendStroke={true}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}

            <Label
              value="%"
              fontSize={'14px'}
              fontWeight={500}
              color="#000000 !important"
              position="center"
              className="label"
            />
          </Pie>
          <Legend
            verticalAlign="middle"
            iconType="circle"
            align="right"
            layout={'vertical'}
            height={12}
            iconSize={10}
            wrapperStyle={style}
            formatter={renderColorfulLegendText}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
        </PieChart>
      </div>
    </Box>
  );
}

export default ProficiencyLevels;
