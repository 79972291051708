import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Header from './Header';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import StudentInformation from './StudentInformation';
import SubjectSelection from './SubjectSelection';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import {
  getEngagements,
  getStudentOfClassList,
  getReactions,
  getTotals,
} from 'store/Thunk/ParentEducatorDashboardThunk';
import {
  getChildTotals,
  getChildReactions,
  getChildEngagements,
  getChildQR,
  getStudentData,
} from 'store/Thunk/ParentDashboardThunk';
import { useAppSelector } from 'store/store';
function ClassRoomComponent({
  role,
  classRooms,
}: {
  role: string;
  classRooms?: any;
}) {
  const [checked, setChecked] = React.useState([1]);
  const [selected, setSelected] = React.useState(1);
  const [selection, setSelection] = React.useState('ghefcdyegdyw');
  const [newElement, setNewElement] = React.useState([
    {
      classroom: {
        id: 'ghefcdyegdyw',
        name: 'All Classrooms',
      },
    },
  ]);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [newData, setNewData] = React.useState([]);
  const { classroomStudentsList } = useAppSelector(
    (state: any) => state.ParentEducatorReducer,
  );
  // const handleToggle = (value: number) => () => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };
  //==================firsyt render ==========
  useEffect(() => {
    let newArr: any = [...newElement, ...classRooms];
    console.log('newData', newArr);
    setNewData(newArr);
  }, [classRooms]);
  //========get classroom data =========
  const handleClassroomClick = (classroomId: any) => {
    setSelected(classroomId);
    dispatch(getEngagements({ id: classroomId, type: 'CLASS' }));
    dispatch(getReactions({ id: classroomId, type: 'CLASS' }));
    dispatch(getTotals({ id: classroomId, type: 'CLASS' }));
  };
  //========get classroom students data =========
  const handleTabClick = (classroomId: any) => {
    setSelection(classroomId);
    dispatch(getStudentOfClassList(classroomId));
  };
  const handleStudentClick = (id: any) => {
    setSelected(id);
    localStorage.setItem('selectedStudentId', id);
    dispatch(getChildTotals('MATH01'));
    dispatch(getChildEngagements());
    dispatch(getChildReactions());
  };
  return (
    <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg">
      <div className=" items-center justify-between mt-2 mx-2">
        <div className="w-[40%] py-[5px] flex items-center border border-[#DCDCDC] rounded-lg mr-2 ">
          <img
            alt=""
            src={require('../../../../assets/Parent/search.png')}
            className="h-3 w-3 ml-2"
          />
          <input className="text-[12px] ml-2" placeholder="Search"></input>
        </div>
        <div className=" w-full p-[1px] flex border rounded-md border-['#E9EAEB'] items-center overflow-x-scroll mt-2">
          {newData?.map((item: any) => (
            <div
              role="button"
              style={{
                backgroundColor:
                  selection === item?.classroom?.id ? '#20A58A' : 'transparent',
              }}
              onClick={() => handleTabClick(item?.classroom?.id)}
              className={`h-6 w-[40%] rounded-md flex items-center justify-center`}
            >
              <Typography
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  fontWeight: 600,
                  fontSize: 10,
                  textAlign: 'center',
                  color:
                    selection === item?.classroom?.id ? 'white' : '#717680',
                }}
              >
                {item?.classroom?.name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      {selection === 'ghefcdyegdyw' ? (
        <div className="block lg:flex items-center">
          <div
            className="flex items-center"
            style={{
              alignItems: 'center',
              // width: '100%',
              overflowX: 'scroll',
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <List
              dense
              sx={{
                //width: '100%',
                //maxWidth: 360,
                // bgcolor: 'red',
                borderRadius: 10,
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {classRooms?.map((value: any) => {
                return (
                  <div className="flex">
                    <div
                    //className="flex"
                    >
                      <ListItem
                        key={value?.classroom?.id}
                        style={{
                          padding: 0,
                          //height: '50px',
                          // width: '200px',
                          //backgroundColor: 'red',
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          style={{
                            padding: 0,
                            paddingLeft: 10,
                            //paddingTop: 10,
                          }}
                        >
                          <StudentInformation
                            role={'Edu'}
                            // studentImg={value.studentImg}
                            studentName={value?.classroom?.name}
                            grade={value?.classroom?.grade?.name}
                            schoolName={
                              value?.classroom?.SchoolClassroom[0]?.school?.name
                            }
                            selected={
                              selected === value?.classroom?.id ? true : false
                            }
                            onClick={() =>
                              handleClassroomClick(value?.classroom?.id)
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  </div>
                );
              })}
            </List>
          </div>
        </div>
      ) : (
        <div className="block lg:flex items-center">
          <div
            className="flex items-center"
            style={{
              alignItems: 'center',
              // width: '100%',
              overflowX: 'scroll',
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <List
              dense
              sx={{
                //width: '100%',
                //maxWidth: 360,
                // bgcolor: 'red',
                borderRadius: 10,
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {classroomStudentsList?.map((value: any) => {
                return (
                  <div className="flex">
                    <div
                    //className="flex"
                    >
                      <ListItem
                        key={value?.user?.id}
                        style={{
                          padding: 0,
                          //height: '50px',
                          // width: '200px',
                          //backgroundColor: 'red',
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          style={{
                            padding: 0,
                            paddingLeft: 10,
                            paddingTop: 10,
                          }}
                        >
                          <StudentInformation
                            gemsToken="0"
                            role={'student'}
                            studentImg={value?.user?.profileImage}
                            studentName={value?.user?.name}
                            grade={value?.user?.username}
                            schoolName={''}
                            selected={
                              selected === value?.user?.id ? true : false
                            }
                            onClick={() => handleStudentClick(value?.user?.id)}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  </div>
                );
              })}
            </List>
          </div>
        </div>
      )}
    </Box>
  );
}
export default ClassRoomComponent;
