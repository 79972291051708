import { ShareOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Header from './Header';
function TopicsEngamement({
  onClick,
  parent,
  studentData,
  studentQR,
}: {
  onClick: any;
  parent: boolean;
  studentData?: any;
  studentQR?: any;
}) {
  return (
    <>
      {parent ? (
        <Box
          className={
            'lg:w-[56%] border border-[#EAECF0] rounded-lg p-2 mr-1 mt-2'
          }
        >
          <div className="mt-2 ml-2">
            <Header text={'Engagement by Topics/Subjects'} />
            <div className="flex">
              <div className="mt-6 w-[50%] ">
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    textAlign: 'center',
                  }}
                >
                  Download Our Mobile App
                </Typography>
                <div className="flex justify-around mt-4 ">
                  <div
                    role="button"
                    onClick={() =>
                      window.open(
                        'https://apps.apple.com/us/app/g3ms/id1625313492',
                        '_blank',
                      )
                    }
                    className="w-[40%]"
                  >
                    <div className="flex items-center justify-center">
                      <img
                        alt=""
                        src={require('../../../../assets/Parent/app-store.png')}
                        className="h-8 w-8"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Download on the App Store
                    </Typography>
                  </div>
                  <div
                    role="button"
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.g3ms',
                        '_blank',
                      )
                    }
                    className="w-[40%]"
                  >
                    <div className="flex items-center justify-center">
                      <img
                        alt=""
                        src={require('../../../../assets/Parent/play-store.png')}
                        className="h-8 w-8"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Download on the Play Store
                    </Typography>
                  </div>
                </div>
              </div>
              {!studentData==null ? (
                <Box className="border w-[50%] border-[#EAECF0] mt-3 rounded-lg p-2">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 12,
                      // textAlign: 'center',
                    }}
                  >
                    Student Detail
                  </Typography>
                  <div className="flex items-center justify-between mt-3">
                    <div className="border-[1.5px] rounded-full border-[#20A58A] p-2">
                      <img
                        alt=""
                        src={studentData?.profileImage}
                        className="h-9 w-9 "
                      />
                    </div>
                    <img alt="" src={studentQR} className="h-12 w-12" />
                  </div>
                  <div className="w-[50%]">
                    <Typography
                      color={'#20A58A'}
                      sx={{ fontSize: 13, fontWeight: 400, marginTop: 1 }}
                    >
                      {studentData?.name}
                    </Typography>
                    <div className="flex items-center justify-between">
                      <Typography
                        color={'#131515'}
                        sx={{ fontSize: 12, fontWeight: 400, marginTop: 1 }}
                      >
                        {studentData?.username}
                      </Typography>
                      <Typography
                        color={'#131515'}
                        sx={{ fontSize: 12, fontWeight: 400, marginTop: 1 }}
                      >
                        {studentData?.grade_?.name}
                      </Typography>
                    </div>
                  </div>
                </Box>
              ) : null}
            </div>
          </div>
          <div className="w-full mt-3">
            <Button
              onClick={onClick}
              sx={{ width: '100%' }}
              variant="contained"
              startIcon={<ShareOutlined />}
            >
              Download & Share
            </Button>
          </div>
        </Box>
      ) : (
        <Box className={' border border-[#EAECF0] lg:mx-1 rounded-lg p-2 mt-2'}>
          <div className="mt-2 ml-2">
            <Header text={'Engagement by Topics/Subjects'} />
            <div className="flex">
              <div className="mt-6 w-[60%]">
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    textAlign: 'center',
                  }}
                >
                  Download Our Mobile App
                </Typography>
                <div className="flex justify-around mt-4 ">
                  <div
                    role="button"
                    onClick={() =>
                      window.open(
                        'https://apps.apple.com/us/app/g3ms/id1625313492',
                        '_blank',
                      )
                    }
                    className="w-[40%] "
                  >
                    <div className="flex justify-center items-center mb-2">
                      <img
                        alt=""
                        src={require('../../../../assets/Parent/app-store.png')}
                        className="h-8 w-8"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Download on the App Store
                    </Typography>
                  </div>
                  <div
                    role="button"
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.g3ms',
                        '_blank',
                      )
                    }
                    className="w-[40%]"
                  >
                    <div className="flex justify-center items-center mb-2">
                      <img
                        alt=""
                        src={require('../../../../assets/Parent/play-store.png')}
                        className="h-8 w-8"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Download on the Play Store
                    </Typography>
                  </div>
                </div>
              </div>
              <Box className="border  border-[#EAECF0] mt-6 rounded-lg p-4 ml-4">
                <div className="flex items-center justify-center ">
                  <img
                    alt=""
                    src={require('../../../../assets/Parent/bar.png')}
                    className="h-14 w-14"
                  />
                </div>
                <Typography
                  sx={{ fontSize: 13, fontWeight: 600, marginTop: 3 }}
                >
                  Join Me
                </Typography>
              </Box>
            </div>
          </div>
          <div className="w-full mt-3">
            <Button
              onClick={onClick}
              sx={{ width: '100%' }}
              variant="contained"
              startIcon={<ShareOutlined />}
            >
              Download & Share
            </Button>
          </div>
        </Box>
      )}
    </>
  );
}
export default TopicsEngamement;
