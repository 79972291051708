import { Box } from '@mui/material';
import React from 'react';
import Header from './Header';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';

function ToDo({data, edu}:{data?:any; edu?:any}) {
  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  // const data = [
  //   {
  //     id: 1,
  //     img: require('../../../../assets/Parent/Vector.png'),
  //     text: 'Complete a Daily Practice Session.',
  //   },
  //   {
  //     id: 2,
  //     img: require('../../../../assets/Parent/Vector2.png'),
  //     text: 'Explore the AI Tutor for Homework Help.',
  //   },
  //   {
  //     id: 3,
  //     img: require('../../../../assets/Parent/Vector3.png'),
  //     text: 'Earn Tokens by Finishing Challenges.',
  //   },
  //   {
  //     id: 4,
  //     img: require('../../../../assets/Parent/Vector2.png'),
  //     text: 'Explore the AI Tutor for Homework Help.',
  //   },
  // ];
  return (
    <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg mt-2">
      <div className="mt-2 ml-2">
        <Header text="To Do" />
      </div>
      <div
        className="flex items-center"
        style={{
          alignItems: 'center',
          width: '100%',
          overflowX: 'scroll',
          padding: 0,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <List
          dense
          sx={{
            width: '100%',
            maxWidth: 360,
            // bgcolor: 'red',
            borderRadius: 10,
          }}
          className= {edu?'flex':'lg:block flex'}
          // style={{
          //   display: 'flex',
          //   flexDirection: 'row',
          // }}
        >
          {data?.map((value:any) => {
            const labelId = `checkbox-list-secondary-label-${value}`;
            return (
              <div className="flex">
                <div className="flex mx-2 rounded-lg border border-[#20A58A]  mb-2">
                  <ListItem
                    key={value.id}
                    style={{
                      //height: '50px',
                      width: '220px',
                    }}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(value.id)}
                        checked={checked.includes(value.id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <div className='flex items-center justify-center h-[30px] w-[30px] border-[1px] border-["#E9EAEB] rounded-md'>
                          <Avatar
                            sx={{
                              height: 20,
                              width: 20,
                            }}
                            alt={`Avatar`}
                            src={value.img}
                          />
                        </div>
                      </ListItemAvatar>
                      <ListItemText
                        // className="text-xs"
                        // sx={{ fontWeight: 500, fontSize: 11 }}
                        primaryTypographyProps={{
                          fontSize: '11px',
                          width: '100%',
                        }}
                        id={labelId}
                        primary={value.text}
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              </div>
            );
          })}
        </List>
      </div>
    </Box>
  );
}

export default ToDo;
