import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLocalStorage } from '@mantine/hooks';

import { LoadingOverlay } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import Logo from 'components/NewLogo';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetSubscriptionsQuery,
  useIniTiatePaymentMutation,
} from 'store/apis/payment';
import { Plan, TLineItems } from 'store/apis/payment/types';
import {
  useDeleteMyProfileMutation,
  useGetMyProfileQuery,
} from 'store/apis/profile';
import { auth } from 'store/firebase';
import { logoutUser, selectUser } from 'store/Slice/authSlice';
import cn from 'utils/cn';
import useAuthRedirectHandler from '../../hooks/useAuthRedirectHandler';
import PaymentPlanCard from './components/PaymentCard';

const PaymentPlanList = () => {
  const { loginUser } = useAuthRedirectHandler();

  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const { data, isLoading } = useGetSubscriptionsQuery();

  const dispatch = useDispatch();

  const { openModal, closeModal } = useModal();

  const { data: userData, isLoading: loading } = useGetMyProfileQuery();
  const [deleteAccount, deleteAccountResponse] = useDeleteMyProfileMutation();

  const user = useSelector(selectUser);

  const [searchParams] = useSearchParams();

  const userId = user?.id || userData?.data?.id;

  const parentId = searchParams.get('parentId');

  const navigate = useNavigate();

  const [paymentRequest, paymentRequestResponse] = useIniTiatePaymentMutation();

  const initiatePayment = async (items: TLineItems) => {
    try {
      if (!parentId && !userId) {
        openModal('WarningModal', {
          title: 'Warning',
          description: 'You need to login to proceed',
          onContinue: () => {
            navigate('/login');
          },
        });

        return;
      }
      const res = await paymentRequest({
        items: items,
        userId: userId || parentId,
      }).unwrap();
      closeModal();
      window.location.href = res.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount().unwrap();
      auth.signOut();
      dispatch(logoutUser());
      localStorage.clear();
      navigate('/login');
    } catch (error: any) {
      openModal('WarningModal', {
        title: 'Error',
        description: error?.data?.message
          ? error.data.message
          : 'Something went wrong',
      });
    }
  };

  if (isLoading || loading) return <LoadingOverlay visible />;

  const userName = userData
    ? `${userData?.data?.name} ${userData?.data?.lastname}`
    : '';

  const isAuthenticated = userData?.data;

  return (
    <>
      <div className="p-4 min-h-[100dvh] bg-[#F7F7F7]">
        <div className="bg-white rounded-2xl shadow-[0px_4px_16px_0px_#0000001A] p-4 grid gap-4 !h-[calc(100dvh-2rem)] overflow-y-auto">
          {!isAuthenticated ? (
            <header>
              <Logo />
              <h2 className="text-[#131515] text-2xl font-bold text-center mt-8 mb-4">
                Hey {userName}! Let’s select the best G3MS plan for you.
              </h2>
            </header>
          ) : (
            <div className="flex !w-full mb-4">
              <button
                onClick={() => navigate(-1)}
                className="fixed top-10 left-10"
              >
                <img src={'/svg/go_back_icon.svg'} alt="Go Back Icon" />
              </button>
            </div>
          )}

          <div className="max-w-[100rem] mx-auto border shadow-[0px_-2px_16px_0px_#20A58A40_inset] flex flex-col gap-4 px-3.5 py-3 rounded-2xl border-solid border-[#00000033] sm:px-5 sm:py-4">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-14 sm:px-4 py-6 sm:py-10 max-w-xl lg:max-w-full w-full mx-auto">
              {data?.data
                ?.filter((item) => item?.packageType !== 'ADD_ON')
                .map((item, _index) => (
                  <PaymentPlanCard
                    key={_index}
                    planName={item.name}
                    price={item.price}
                    features={item?.features?.map((feature) => feature)}
                    isRecommended={item.isRecommended}
                    description={item.description}
                    onSubscribe={() => {
                      openModal('PaymentDetails', {
                        plan: item,
                        onConfirm: (items: TLineItems) => {
                          initiatePayment(items);
                        },
                      });
                    }}
                    className={cn({
                      'order-3': _index === 0,
                      'order-1': _index === 2,
                      'order-2': _index === 1,
                    })}
                    onClick={() => setSelectedPlan(item)}
                    selected={selectedPlan?.id === item.id}
                    isCurrentPlan={
                      userData?.data?.activeSubscriptions?.find(
                        (prod) => prod.product?.id === item?.stripeProductId,
                      ) !== undefined
                    }
                    additionalUserPrice={
                      item?.pricingTier?.tiers?.find(
                        (tier) => tier?.up_to === 'inf',
                      )?.price
                    }
                    interval={item.interval}
                  />
                ))}
            </div>
          </div>
          <div
            className={cn('flex items-center w-full gap-6 flex-wrap mt-4', {
              'justify-center': !isAuthenticated,
            })}
          >
            {isAuthenticated && (
              <button
                onClick={() => {
                  openModal('WarningModal', {
                    title: 'Are you sure?',
                    description:
                      'Are you sure you want to delete your account? This action cannot be undone.',
                    onContinue: handleDeleteAccount,
                  });
                }}
                className="text-white text-xs sm:text-sm lg:text-base xl:text-xl font-semibold bg-[#FF3B30] py-3 px-6 rounded-2xl  "
              >
                Delete Account
              </button>
            )}
            {!userData?.data?.isUserUpgradedPlan && (
              <>
                <button
                  onClick={() => {
                    const token = localStorage.getItem('token');
                    if (token && userData?.data) {
                      loginUser(userData?.data, token);
                    } else {
                      navigate('/login');
                    }
                  }}
                  className="text-white text-xs sm:text-sm lg:text-base xl:text-xl font-bold bg-[#929292] py-3 px-6 rounded-2xl"
                >
                  Keep the FREE plan
                </button>
                <button
                  className="text-xs sm:text-sm lg:text-base xl:text-xl text-[#C73E9D]"
                  onClick={() => openModal('ApplyPromoModal')}
                >
                  Got a Promo or Referral Code?
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <LoadingOverlay
        visible={
          paymentRequestResponse.isLoading ||
          loading ||
          deleteAccountResponse.isLoading
        }
      />
    </>
  );
};

export default PaymentPlanList;
