import * as React from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const renderColorfulLegendText = (value: string, entry: any) => {
  const { color } = { color: 'black' };
  return <span style={{ color }}>{value}</span>;
};
const style = {
  lineHeight: '16px',
  color: 'black',
  fontSize: '10px',
};
const ITEM_HEIGHT = 48;
function EngagementbyTopics({ data, from }: { data?: any; from?: any }) {
  console.log('engagements from components', data);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = React.useState(
    'Engagement by Topics',
  );
  const [graphData, setGraphData] = React.useState();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option: any) => {
    setSelectedValue(option);
    console.log(option);
    setAnchorEl(null);
  };
  React.useEffect(() => {
    setGraphData(data?.dailyAssigmentsAccessments?.engagementsByTopic);
  }, [data]);
  React.useEffect(() => {
    if (selectedValue === 'Engagement by Video Topics') {
      setGraphData(data?.videoAccessments?.engagementsByTopic);
    } else if (selectedValue === 'Engagement by Assesments') {
      setGraphData(data?.dailyAssigmentsAccessments?.engagementsByTopic);
    } else if (selectedValue === 'Engagement by Topics') {
      setGraphData(data?.byTopics);
    } else {
      setGraphData(data?.byTopics);
      setSelectedValue("Engagement by Topics")
    }
  }, [
    selectedValue,
    data?.videoAccessments?.engagementsByTopic,
    data?.dailyAssigmentsAccessments?.engagementsByTopic,
    data?.byTopics
  ]);
  const options = [
    'Engagement by Topics',
    'Engagement by Video Topics',
    'Engagement by Assesments',
  ];
  return (
    <Box className="border border-[#EAECF0] mt-2  rounded-lg py-2 px-2 mr-1 lg:w-[70%] w-full">
      <div className="flex items-center justify-between">
        <Header text={selectedValue} />
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <img
              alt=""
              src={require('../../../../assets/Parent/dots-vertical.png')}
              className="h-4 w-4"
            />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '30ch',
                },
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                selected={option === 'Engagement by Topics'}
                onClick={() => handleClose(option)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      <div>
        <BarChart
          layout="vertical"
          width={from === 'edu' ? 290 : 320}
          height={200}
          data={graphData}
          margin={{
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
          }}
        >
          <CartesianGrid stroke="#F5F5F5" />
          <XAxis
            axisLine={false}
            tickLine={false}
            label={{ offset: 0 }}
            style={{
              fontSize: '10px',
              color: 'black',
              fontWeight: 600,
            }}
            type="number"
          />
          <YAxis
            style={{
              fontSize: '9px',
              color: 'black',
              fontWeight: 500,
            }}
            dataKey="title"
            type="category"
          />
          <Tooltip />
          <Legend
            verticalAlign="bottom"
            iconType="circle"
            align="center"
            layout={'horizontal'}
            height={12}
            iconSize={10}
            wrapperStyle={style}
            formatter={renderColorfulLegendText}
          />
          <Bar
            dataKey="Classroom"
            radius={[15, 0, 0, 15]}
            stackId="a"
            barSize={15}
            fill="#1D957C"
          />
          <Bar
            dataKey="Mobile"
            radius={[0, 15, 15, 0]}
            stackId="a"
            barSize={15}
            fill="#C44297"
          />
        </BarChart>
      </div>
    </Box>
  );
}
export default EngagementbyTopics;
