import { Typography } from '@mui/material';
import * as React from 'react';
import CustomizedTextField from '../AddStudent/CustomizedTextField';

function InputComponent({
  helpingText,
  heading,
  heading2,
  label,
  label2,
  label3,
  label4,
  more,
  addMore,
  fourBlock,
}: {
  helpingText: string;
  heading: string;
  label: string;
  heading2: string;
  label2: string;
  label3: string;
  label4: string;
  more: boolean;
  addMore: any;
  fourBlock: boolean;
}) {
  return (
    <>
      {more ? (
        <div className="flex items-center justify-between mt-4 px-2">
          <Typography
            sx={{
              fontSize: 13,
              fontWeight: 400,
              fontFamily: 'inter',
            }}
            color={'#000000'}
          >
            {helpingText}
          </Typography>
          <div onClick={addMore}>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 500,
                fontFamily: 'inter',
              }}
              color={'#C73E9D'}
            >
              +Add more
            </Typography>
          </div>
        </div>
      ) : (
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            fontFamily: 'inter',
            marginTop: 4,
            marginLeft: 2,
          }}
          color={'#000000'}
        >
          {helpingText}
        </Typography>
      )}
      {fourBlock == false ? (
        <div className="w-full flex px-2 space-x-1 items-center">
          <CustomizedTextField heading={heading} label={label} />
          <CustomizedTextField heading={heading2} label={label2} />
        </div>
      ) : (
        <div className="w-full flex px-2 space-x-1 items-end">
          <CustomizedTextField heading={heading} label={label} />
          <CustomizedTextField heading={' '} label={label3} />
          <CustomizedTextField heading={heading2} label={label2} />
          <CustomizedTextField heading={' '} label={label4} />
        </div>
      )}
    </>
  );
}

export default InputComponent;
