import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useModal from 'components/Modal/hook/useModal';
import AddStudentButtons from 'pages/parent/components/AddStudent/AddStudentButtons';
import AddStudentComponent from 'pages/parent/components/AddStudent/AddStudentComponent';
import Progresss from 'pages/parent/components/Progress/Progresss';

function ParentFreePlanStep5() {
  const { closeModal, openModal, isOpen } = useModal();

  const val = 100;
  const text = '3 / 3';

  const heading = 'Add another Parent';

  return (
    <div>
      <Modal
        open={isOpen('ParentFreePlanStep5')}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="innerboxShadow w-96"
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: view == 3 ? 380 : view == 6 ? 300 : 440,
            //width: 700,
            padding: 3,
            backgroundColor: '#fff',
            boxShadow: 'shadow-inner-2xl',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <div
            onClick={closeModal}
            className=" flex md:flex lg:flex justify-end pr-4 pt-4"
          >
            <img src="/images/cross.png" style={{ height: 8, width: 8 }} />
          </div>

          <div className={' flex mt-3 ml-4'}>
            <div>
              <Progresss
                value={val}
                style={{ height: 50, width: 50 }}
                text={text}
                stroke="#C73E9D"
              />
            </div>
            <div className="mx-3">
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 700,
                  //textAlign: 'center',
                }}
                color={'#2C3E50'}
              >
                {heading}
              </Typography>
            </div>
          </div>

          <AddStudentComponent type="parent" />

          <div className="mt-5">
            <AddStudentButtons
              bg2={false}
              bg={'#C73E9D'}
              title1="Add another Parent"
              title2="Skip"
              wd={'40%'}
              onPressNo={closeModal}
              onPressYes={() => {
                // set view to 6
                openModal('ParentFreePlanStep6');
              }}
            />
          </div>
          <div>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 12,
                marginTop: 2,
                marginLeft: 6,
              }}
            >
              Would you like to add another parent to manage Child account?
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ParentFreePlanStep5;
