import Input from 'components/Input';
import { useFormik } from 'formik';

import { Modal, rem } from '@mantine/core';
import CustomButton from 'components/CustomButton';
import useModal from 'components/Modal/hook/useModal';
import { Link } from 'react-router-dom';
import cn from 'utils/cn';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  promo: Yup.string().required('Required'),
});

export default function ApplyPromoModal() {
  const { closeModal, isOpen } = useModal();

  const formik = useFormik({
    initialValues: {
      promo: '',
    },
    validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('ApplyPromoModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      title="Promo or Referral Code"
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      closeButtonProps={{
        className:
          'text-[#2C3E50] text-lg font-semibold lg:text-xl 2xl:text-2xl',
        size: 'lg',
      }}
      zIndex={999999999999}
    >
      <form className="flex flex-col gap-6" onSubmit={formik.handleSubmit}>
        <Input
          label="Enter code"
          placeholder="Enter your code..."
          value={formik.values.promo}
          onChange={formik.handleChange}
          name="promo"
          error={formik.errors.promo}
          onBlur={formik.handleBlur}
        />

        <Link
          to={'/qr?redirect=plans'}
          className={cn(
            'shadow-sm shadow-slate-200 p-8 grid gap-8 place-items-center rounded-2xl w-full mx-auto cursor-pointer border border-slate-200',
          )}
          onClick={closeModal}
        >
          <img src={'qr.svg'} alt="qr" className="size-32" />
          <h2 className="font-semibold text-xl xl:text-2xl text-center">
            Scan QR Code
          </h2>
        </Link>

        <CustomButton
          className="max-w-none w-full mt-4 bg-[#20a58a] hover:bg-[#1d8e78] focus:bg-[#1d8e78] active:bg-[#1d8e78] text-white border-[#20a58a] hover:border-[#1d8e78] focus:border-[#1d8e78] active:border-[#1d8e78] 
          hover:text-white"
          variant="primary"
          type="submit"
        >
          Submit
        </CustomButton>
      </form>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
