import { useState, useEffect, useId } from 'react';
import * as ReactDOM from 'react-dom';

const Portal = ({ containerId, children }) => {
  const [isMounted, setIsMounted] = useState(false);
  const id = useId();

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  if (isMounted) {
    const container = document.getElementById(containerId || id);
    if(!container) {
      const newContainer = document.createElement('div');
      newContainer.id = containerId || id;
      document.body.appendChild(newContainer);
    }
    return ReactDOM.createPortal(
      children,
      document.getElementById(containerId),
    );
  }
  return null;
};

export default Portal;
