import { Box } from '@mui/material';
import React from 'react';
import Header from './Header';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { ReactI18NextChild } from 'react-i18next';
const renderColorfulLegendText = (value: string, entry: any) => {
  const { color } = { color: 'black' };

  return <span style={{ color }}>{value}</span>;
};
const data = [
  {
    name: 'Tier 1',
    Exceeding: 4000,
    Meeting: 2400,
    Approaching: 2400,
    Emerging: 2000,
  },
  {
    name: 'Tier 2',
    Exceeding: 3000,
    Meeting: 1398,
    Approaching: 2210,
    Emerging: 2000,
  },
  {
    name: 'Tier 3',
    Exceeding: 2000,
    Meeting: 9800,
    Approaching: 2290,
    Emerging: 2000,
  },
];

const style = {
  lineHeight: '16px',
  color: 'black',
  fontSize: '10px',
};
function TierSupport() {
  return (
    <Box className="border border-[#EAECF0] rounded-lg p-2 mt-2 mx-1">
      <Header text="Proficiency vs RTI Tier Support" />
      <div className="mt-2 mb-2">
        <BarChart
          width={300}
          height={150}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            axisLine={false}
            tickLine={false}
            label={{ offset: 0 }}
            dataKey="name"
            style={{
              fontSize: '12px',
              color: 'black',
            }}
          />

          <Tooltip />
          <Legend
            verticalAlign="bottom"
            iconType="circle"
            align="center"
            layout={'horizontal'}
            height={12}
            iconSize={10}
            wrapperStyle={style}
            formatter={renderColorfulLegendText}
          />
          <Bar dataKey="Exceeding" stackId="a" fill="#3498DB" />
          <Bar dataKey="Meeting" stackId="a" fill="#20A58A" />
          <Bar dataKey="Approaching" stackId="a" fill="#FFD700" />
          <Bar dataKey="Emerging" stackId="a" fill="#FF6B6B" />
        </BarChart>
      </div>
    </Box>
  );
}

export default TierSupport;
