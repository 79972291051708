import { Box, Stack, Typography } from '@mui/material';

import useModal from 'components/Modal/hook/useModal';
import React from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';
import sharedStyles from './sharedStyle.module.scss';

export default function QrScanner() {
  const navigate = useNavigate();


  const { openModal } = useModal();

  const onReadSuccess = async (qrCode: string) => {
    try {
      openModal('QrCodeSuccessModal');
      navigate('/plans?promo=' + qrCode + '&showModal=true');
    } catch (error) {
      openModal('QrCodeErrorModal');
    }
  };

  return (
    <Box className={sharedStyles.qrContainer}>
      <Box className={sharedStyles.arrowFloat}>
        <img
          src={'/images/Arrow-Left.png'}
          alt="img-discription"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(-1);
            // setStep((prevStep) => ({
            //   ...prevStep,
            //   current: 'home',
            // }));
          }}
        />
      </Box>
      <Box className={sharedStyles.avatar}>
        {/* <img src={QRCode} className={sharedStyles.qrCode} alt={'logo'} /> */}
        <video id="qr-video" className={sharedStyles.qrCode}></video>
        <img src={'/images/QrBorder.png'} alt={'logo'} />
      </Box>
      <QrReader
        onResult={(result) => {
          if (!!result) {
            // @ts-ignore
            onReadSuccess(result?.text);
          }
        }}
        // @ts-ignore
        style={{ width: '100%' }}
        videoId="qr-video"
        videoStyle={{ width: '90%', height: '100%' }}
        constraints={{
          facingMode: 'user',
          aspectRatio: { ideal: 1 },
        }}
      />

      <Stack sx={{ '& .MuiTypography-root': { textAlign: 'center' } }}>
        <Typography
          className="!font-semibold"
          style={{ color: '#131515', fontSize: '32px' }}
        >
          Scan QR code to sign in
        </Typography>
      </Stack>
    </Box>
  );
}
