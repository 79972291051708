import CustomButton from 'components/CustomButton';
import cn from 'utils/cn';

type PaymentPlanCardProps = {
  planName: string;
  price: number;
  features?: string[];
  onSubscribe: () => void;
  description: string;
  isRecommended: boolean;
  className?: string;
  onClick: () => void;
  selected: boolean;
  isCurrentPlan: boolean;
  additionalUserPrice?: number;
  interval: 'YEARLY' | 'MONTHLY' | 'ONE_TIME';
};

const textToShow: Record<string, { text1: string; text2?: string }> = {
  YEARLY: {
    text1: 'Per year for 1 student.',
  },
  MONTHLY: {
    text1: 'Per month for 1 student.',
  },
  ONE_TIME: {
    text1: '3-month trial for up to 30 students.',
    text2: 'Perfect for trying G3MS in your classroom or tutoring sessions!',
  },
};

const PaymentPlanCard = ({
  planName,
  price,
  features = [],
  onSubscribe,
  description,
  isRecommended,
  className,
  onClick,
  selected,
  isCurrentPlan,
  additionalUserPrice,
  interval,
}: PaymentPlanCardProps) => {
  return (
    <div
      className={cn(
        'p-5 shadow-lg rounded-2xl !bg-white flex flex-col justify-between gap-4 relative ring-8 hover:ring-[#347769] ring-white hover:ring-8 transition-all duration-300 cursor-pointer',
        '[&_*]:transition-all [&_*]:duration-300 [&_*]:cursor-pointer',
        {
          'ring-[#347769] ring-8': selected,
        },
        className,
      )}
      onClick={onClick}
    >
      <div className="flex flex-col gap-4">
        <h2 className="capitalize text-2xl font-bold text-[#131515] mt-8">
          {planName}
        </h2>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-2 sm:gap-4">
          <h1 className="font-bold text-[#131515] text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl ">
            ${price}
          </h1>
          <div className="flex flex-col gap-2">
            {textToShow[interval]?.text1 && (
              <p className="text-[#84818A] text-xs md:text-sm lg:text-base max-w-[24ch]">
                {textToShow[interval].text1}
              </p>
            )}
            {textToShow[interval]?.text2 && (
              <p className="text-[#C44297] text-xs md:text-sm lg:text-base max-w-[24ch] font-medium">
                {textToShow[interval].text2}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="grid gap-4">
        {additionalUserPrice && (
          <div className="grid gap-3 py-1">
            <h4 className="font-semibold text-[#20A58A]">Additional User</h4>
            <div className="flex items-end gap-2">
              <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-[2.5rem] font-medium text-[#131515]">
                ${additionalUserPrice}
              </h2>
              <p>Per {interval === 'YEARLY' ? 'Year' : 'Month'}</p>
            </div>
          </div>
        )}

        <h3 className="font-semibold text-2xl text-[#131515] uppercase">
          FEATURES
        </h3>

        <ul className="grid gap-2">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
      <CustomButton
        onClick={onSubscribe}
        className="max-w-full w-full mt-4"
        variant={isCurrentPlan ? 'outline' : 'primary'}
        disabled={isCurrentPlan}
        type="button"
      >
        {isCurrentPlan ? 'Current Plan' : 'Subscribe'}
      </CustomButton>

      {planName?.toLowerCase().includes('trial') && (
        <p className="font-semibold text-[#475467] text-base text-center  md:text-lg xl:text-xl">
          Not ready yet? Start with the Free Plan and explore core Math lessons!
        </p>
      )}

      {isRecommended && (
        <div className="flex absolute top-0 -left-2 -right-2 -translate-y-8 text-white text-center justify-center items-center px-4 h-16 rounded-2xl bg-[linear-gradient(90deg,_#347769_0%,_#20A58A_100%)]">
          Recommended
        </div>
      )}
    </div>
  );
};

export default PaymentPlanCard;
