import * as React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

function CustomizedTextField({
  heading,
  label,
}: {
  heading: any;
  label: string;
}) {
  return (
    <div className="w-full">
      {heading ? (
        <Typography
          sx={{ fontSize: 12, fontWeight: 500, color: '#484848', marginTop: 3 }}
        >
          {heading}
        </Typography>
      ) : null}

      <TextField
        placeholder={label}
        sx={{
          '& ::placeholder': { fontSize: 'small' },
          width: '98%',
          height: 30,
        }}
        id="outlined-basic"
        //label={label}
        variant="outlined"
        size="small"
      />
    </div>
  );
}

export default CustomizedTextField;
