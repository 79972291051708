import React from 'react';
import modals from '../../../modals/index';
import ModalContext, { TModal } from '../state/ModalContext';
import { setModal } from '../state/ModalReducer';

type ModalName = keyof typeof modals;

export default function useModal<T,>() {
  const { state, dispatch } = React.useContext(ModalContext);

  

  const openModal = React.useCallback(<T,>(name: ModalName, props?: T) => {
    dispatch(setModal({ name, props }));
  }, [dispatch]);

  const closeModal = React.useCallback(() => {
    state?.modal?.props?.onClose?.();
    dispatch(setModal(undefined));
  }, [dispatch, state?.modal?.props]);

  const isOpen = React.useCallback(
    (name: ModalName) => {
      return state?.modal?.name === name;
    },
    [state?.modal?.name],
  );
  const getModal = <T,>(name: ModalName): TModal<T> | undefined => {
    return state?.modal?.name === name ? state?.modal : undefined;
  }

  return {
    openModal,
    closeModal,
    modal: state?.modal as TModal<T> | undefined,
    isOpen,
    getModal,
  };
}
