import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useModal from 'components/Modal/hook/useModal';
import UpgradeCard from 'pages/parent/components/UpgradePlan/UpgradeCard';

function ParentFreePlanStep6() {
  const { closeModal, openModal, isOpen } = useModal();

  const val = 100;
  const text = '3 / 3';

  const heading = 'Add another Parent';

  return (
    <div>
      <Modal
        open={isOpen('ParentFreePlanStep6')}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="innerboxShadow w-96"
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: view == 3 ? 380 : view == 6 ? 300 : 440,
            //width: 700,
            padding: 3,
            backgroundColor: '#fff',
            boxShadow: 'shadow-inner-2xl',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <UpgradeCard
            onPress={() => console.log('hello')}
            from={'modal'}
            fw={'500'}
            styl={{
              fontWeight: 700,
              fontSize: 10,
              marginLeft: 3,
              marginRight: 2,
            }}
            stroke={'#C44297'}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default ParentFreePlanStep6;
