import { Typography } from '@mui/material';
import BenifitsText from './BenifitsText';

function FreePlanBenifits({
  name,
  childName,
}: {
  name: string;
  childName: string;
}) {
  return (
    <>
      <Typography
        sx={{ marginLeft: 3, fontWeight: 800, fontSize: 15, marginTop: 2 }}
        color="#2C3E50"
      >
        🎉 Welcome to G3MS, {name}
      </Typography>

      <Typography
        sx={{ marginLeft: 3, fontWeight: 400, fontSize: 11, marginTop: 2 }}
        fontFamily="Inter"
        color="#000000"
      >
        Thank you for joining G3MS to support {childName}'s learning journey.
        Here’s what they can do with the Free Plan:
      </Typography>
      <div className="mt-2">
        <BenifitsText
          fw={'400'}
          classNameM="mt-2"
          color={'#000000'}
          classNameD="text-[#000000]"
          text={'✅ Access state-aligned Math lessons'}
        />
        <BenifitsText
          fw={'400'}
          classNameM="mt-2"
          classNameD="text-[#000000]"
          color={'#000000'}
          text={'✅ Earn tokens to customize their avatar'}
        />

        <BenifitsText
          fw={'400'}
          classNameM="mt-2"
          classNameD="text-[#000000]"
          color={'#000000'}
          text={'✅ Use the camera for interactive challenges.'}
        />
      </div>
    </>
  );
}

export default FreePlanBenifits;
