import { Typography } from '@mui/material';
import React from 'react';

function Bottom() {
  return (
    <div className="w-full flex items-center justify-between px-3 py-5">
      <Typography
        sx={{
          fontWeight: 300,
          fontSize: 11,
        }}
        fontFamily="Inter"
        color="#131515"
      >
        Help Center
      </Typography>
      <Typography
        sx={{
          fontWeight: 300,
          fontSize: 11,
        }}
        fontFamily="Inter"
        color="#131515"
      >
        Privacy Policy
      </Typography>
      <Typography
        sx={{
          fontWeight: 300,
          fontSize: 11,
        }}
        fontFamily="Inter"
        color="#131515"
      >
        Terms of Use
      </Typography>
    </div>
  );
}

export default Bottom;
