import { TModal } from './ModalContext';

export const SET_MODAL = 'SET_MODAL';
export const CLEAR_MODAL = 'CLEAR_MODAL';

/*
 * @typedef {Object} ModalState
 * @property {TModal} modal
 */

export const setModal = (modal: TModal<any> | undefined) => {
  return {
    type: SET_MODAL,
    payload: modal,
  };
};

export const clearModal = () => {
  return {
    type: CLEAR_MODAL,
  };
};
