import { Typography } from '@mui/material';
import * as React from 'react';
import AddStudentButtons from '../AddStudent/AddStudentButtons';

function UpgradeToEducator({
  upgrade,
  keepOldPlan,
}: {
  upgrade: any;
  keepOldPlan: any;
}) {
  const data = [
    {
      text: '📊 Track Progress: ',
      val: 'See [Child’s Name]’s performance and activity.',
    },
    {
      text: '✅ Assign Tasks: ',
      val: 'Customize quizzes and challenges.',
    },
    {
      text: '🔍 Spot Gaps: ',
      val: 'Identify strengths and areas for improvement.',
    },
    {
      text: '🎉 Celebrate Wins: ',
      val: 'Track milestones and reward success.',
    },
  ];
  return (
    <div className="ml-6 mr-4 mt-4">
      <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
        Take [Child’s Name]’s learning experience to the next level with the
        Educator Dashboard!
        <div style={{ fontSize: 12, fontWeight: 700, marginTop: 3 }}>
          With the upgraded dashboard, you’ll have powerful tools to:
        </div>
      </Typography>
      <div className="mt-4">
        {data.map((item) => (
          <Typography sx={{ fontSize: 12, fontWeight: 700, marginTop: 1 }}>
            {item.text}
            <span style={{ fontSize: 12, fontWeight: 400 }}>{item.val}</span>
          </Typography>
        ))}
      </div>
      <div style={{ fontSize: 12, fontWeight: 700, marginTop: 20 }}>
        Upgrade now to stay involved and support their growth!
      </div>
      <div className="mt-5">
        <AddStudentButtons
          bg2={'#929292'}
          bg={'#C73E9D'}
          title1="Upgrade to Educator"
          title2="Keep the Parent Plan"
          wd={'40%'}
          onPressNo={upgrade}
          onPressYes={keepOldPlan}
        />
      </div>
    </div>
  );
}

export default UpgradeToEducator;
