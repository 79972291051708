
import {Typography } from '@mui/material';

function WelcomeText({name, role}) {
    return (
        <Typography className=" !text-black !text-xl ">
            <span
              style={{ color: '#C73E9D', fontSize: '18px', fontWeight: '500' }}
            >
              {' '}
              {role=="parentNotOnboarded"?"Welcome to G3MS":" Welcome  Back,"}
             
            </span>
            <span
              style={{ color: '#20A58A', fontSize: '18px', fontWeight: '500' }}
            >
              {' '}
             {name}
            </span>
          </Typography>
          
    );
}

export default WelcomeText;