import { Typography, Box } from '@mui/material';
import * as React from 'react';
function SkillBox({
  img,
  text,
  role,
  values,
}: {
  img: any;
  text: string;
  role: any;
  values: string;
}) {
  return (
    <Box className="border rounded-xl border-[#E9EAEB] lg:w-2/12 md:w-2/12 lg:m-0 m-1">
      {
        !role ? (
          <div className="flex items-center justify-center px-2 py-2">
            <div className="flex flex-col justify-center items-center">
              <img src={img} alt="" className="h-4 w-4" />
              <Typography
                color={'#535862'}
                sx={{ fontSize: 10, fontWeight: 600, marginTop: 1 }}
              >
                {text}
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-start px-2 mt-2">
              <img src={img} alt="" className="h-[15px] w-[15px]" />
              <Typography
                color={'#535862'}
                sx={{ fontSize: 7, fontWeight: 600, marginLeft: 2 }}
              >
                {text}
              </Typography>
            </div>
            <div className="ml-3 mt-2 mb-1">
              <Typography
                sx={{ fontWeight: 600, fontSize: 13 }}
                color={'#101828'}
              >
                {text === 'Skills Mastered' ? values + ' Skills' : values + '%'}
              </Typography>
            </div>
          </>
        )
        // : (
        //   <>
        //     <div className="flex items-center justify-start px-2 mt-2">
        //       {role === 'EduNoOnbrded' ? null : (
        //         <img src={img} alt='' className="h-[15px] w-[15px]" />
        //       )}
        //       <Typography
        //         color={'#535862'}
        //         sx={{ fontSize: 7, fontWeight: 600, marginLeft: 2 }}
        //       >
        //         {text}
        //       </Typography>
        //     </div>
        //     <div className="ml-3 mt-2 mb-1">
        //       <Typography
        //         sx={{ fontWeight: 600, fontSize: 15 }}
        //         color={'#101828'}
        //       >
        //         {role === 'EduNoOnbrded' ? '%' : values}
        //       </Typography>
        //     </div>
        //   </>
        // )
      }
    </Box>
  );
}
export default SkillBox;
