import { Typography } from '@mui/material';
import React from 'react';
function SubjectSelection({
  selected,
  onClickMath,
  onClickScience,
  onClickELA,
  onClickTest,
}: {
  selected: string;
  onClickMath: any;
  onClickScience: any;
  onClickELA: any;
  onClickTest: any;
}) {
  return (
    <div className="hidden w-[30%] p-[1px] lg:flex border rounded-md border-['#E9EAEB'] items-center ">
      <div
        style={{
          backgroundColor: selected === 'MATH01' ? '#20A58A' : 'transparent',
        }}
        onClick={onClickMath}
        className={`h-6 w-[100%] rounded-md flex items-center justify-center`}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 10,
            color: selected === 'MATH01' ? 'white' : '#717680',
          }}
        >
          Math
        </Typography>
      </div>
      {/* <div
        style={{
          backgroundColor: selected == 'ela' ? '#20A58A' : 'transparent',
        }}
        onClick={onClickELA}
        className={`h-6 w-[30%] rounded-md flex items-center justify-center`}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 10,
            color: selected == 'ela' ? 'white' : '#717680',
          }}
        >
          Ela
        </Typography>
      </div>
      <div
        style={{
          backgroundColor: selected == 'science' ? '#20A58A' : 'transparent',
        }}
        onClick={onClickScience}
        className="h-6 w-[30%] rounded-md flex items-center justify-center bg-[red]]"
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 10,
            color: selected == 'science' ? 'white' : '#717680',
          }}
        >
          Science
        </Typography>
      </div>
      <div
        style={{
          backgroundColor: selected == 'test' ? '#20A58A' : 'transparent',
        }}
        onClick={onClickTest}
        className="h-6 w-[30%] rounded-md flex items-center justify-center bg-[red]]"
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 10,
            color: selected == 'test' ? 'white' : '#717680',
          }}
        >
          ISEE - Test Prep
        </Typography>
      </div> */}
    </div>
  );
}
export default SubjectSelection;
