import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import CustomizedTextField from './CustomizedTextField';
import { height } from '@mui/system';
import PhoneInputtt from './PhoneInputtt';

function AddStudentComponent({ type }: { type: string }) {
  const [value, setValue] = React.useState('');
  return (
    <FormControl
      sx={{ marginRight: 3, marginLeft: 3 }}
      component="fieldset"
      variant="standard"
    >
      <FormGroup sx={{ display: 'flex' }}>
        <div
          className={
            type == 'student'
              ? ' grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-2'
              : ' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2'
          }
        >
          <CustomizedTextField heading="First Name" label="Enter Name" />
          <CustomizedTextField heading="Last Name" label="Enter Name" />
          <CustomizedTextField
            heading="Date of Birth"
            label="Enter date of birth"
          />
          {type == 'student' ? (
            <>
              <CustomizedTextField
                heading="Grade/Subject"
                label="Enter date of birth"
              />
              <CustomizedTextField heading="Username" label="Enter username" />
              <CustomizedTextField
                heading="Confirm School"
                label="Enter school/company code"
              />
            </>
          ) : (
            <>
              <CustomizedTextField
                heading="Email Address"
                label="Enter email address"
              />
            </>
          )}
        </div>
      </FormGroup>
    </FormControl>
  );
}

export default AddStudentComponent;
