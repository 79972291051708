import ApplyPromoModal from './ApplyPromoModal';
import ParentFreePlanStep1 from './ParentFreePlanStep1';
import ParentFreePlanStep2 from './ParentFreePlanStep2';
import ParentFreePlanStep3 from './ParentFreePlanStep3';
import ParentFreePlanStep4 from './ParentFreePlanStep4';
import ParentFreePlanStep5 from './ParentFreePlanStep5';
import ParentFreePlanStep6 from './ParentFreePlanStep6';
import PaymentDetails from './PaymentDetails';
import QrCodeErrorModal from './QrCodeErrorModal';
import QrCodeSuccessModal from './QrCodeSuccessModal';
import WarningModal from './WarningModal';

const modals = {
  PaymentDetails,
  WarningModal,
  ApplyPromoModal,
  QrCodeErrorModal,
  QrCodeSuccessModal,
  ParentFreePlanStep1,
  ParentFreePlanStep2,
  ParentFreePlanStep3,
  ParentFreePlanStep4,
  ParentFreePlanStep5,
  ParentFreePlanStep6,
};

export default modals;
