import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';

import {
  setClassRoomList,
  setClassroomStudentList,
  setEngagementList,
  setReactionList,
  setTotals
  } from 'store/Slice/ParentEducatorDashboardSlice';

  export const getClassList = createAsyncThunk(
    'getchildlist',
    
    async (_, { dispatch }) => {
//         const userData: any = localStorage.getItem('userData');
//         const id :any =JSON.parse(userData).id
// console.log("id%%%%%%%%%%", id)
     let url = `/api/v1/dashboard/parent/educator/classes`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassRoomList(response.data.data));
     
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getStudentOfClassList = createAsyncThunk(
    'getStudentList',
    
    async (_id:any, { dispatch }) => {
     let url = `/api/v1/dashboard/parent/educator/students/${_id}`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassroomStudentList(response.data.data.classStudents));
     console.log("ClassroomStudentList", response.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getEngagements = createAsyncThunk(
    'getEngagements',
    
    async (_data:any, { dispatch }) => {
      console.log("in engagements API", _data)
     let url = `/api/v1/dashboard/educator/engagements?id=${_data?.id}&type=${_data.type}`;
      try {
        const response: any = await API.get(url);
        dispatch(setEngagementList(response.data.data));
     console.log("engagements dataaaa",response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getReactions = createAsyncThunk(
    'getReactions',
    
    async (_data:any, { dispatch }) => {
      console.log("in engagements API", _data)
     let url = `/api/v1/dashboard/educator/reactions?id=${_data?.id}&type=${_data.type}`;
      try {
        const response: any = await API.get(url);
        dispatch(setReactionList(response.data.data))
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getTotals = createAsyncThunk(
    'getTotals',
    
    async (_data:any, { dispatch }) => {
     let url = `/api/v1/dashboard/educator/totals?id=${_data?.id}&type=${_data.type}&topicId=MATH01`;
      try {
        const response: any = await API.get(url);
        dispatch(setTotals(response.data.data))
        console.log("totals", response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );