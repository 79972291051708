import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

interface IntialState {
  screenLoading: boolean;
  btnLoading: boolean;
}

const intialState: IntialState = {
  screenLoading: false,
  btnLoading: false,
};

const loadingSlice = createSlice({
  name: 'loading_slice',
  initialState: intialState,
  reducers: {
    startLoading: (state: Draft<IntialState>) => {
      state.screenLoading = true;
    },
    stopLoading: (state: Draft<IntialState>) => {
      state.screenLoading = false;
    },
    setBtnLoading: (
      state: Draft<IntialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.btnLoading = action.payload;
    },
  },
});

export const { startLoading, stopLoading, setBtnLoading } =
  loadingSlice.actions;

export const loadingState = (state: RootState) => state.loadingReducer;

export default loadingSlice.reducer;
