import { Box, Typography } from '@mui/material';
import React from 'react';
import ButtonMain from '../ButtonMain';

function GiftsCard() {
  return (
    <>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: 13,
          textAlign: 'center',
          marginTop: 2,
        }}
        fontFamily="Inter"
        color="#101828"
      >
        Help your student earn gift cards!
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: 10,
          textAlign: 'center',
          marginTop: 2,
        }}
        fontFamily="Inter"
        color="#475467"
      >
        Complete a demo challenge and earn Gifts!
      </Typography>
      <div className="flex items-center justify-evenly lg:justify-between px-2 py-2">
        <img
          className="w-12  h-8"
          src={require('../../../../assets/Parent/image10.png')}
          alt=""
        />
        <img
          className="w-12  h-8"
          src={require('../../../../assets/Parent/image11.png')}
          alt=""
        />
        <img
          className="w-12 h-8"
          src={require('../../../../assets/Parent/image12.png')}
          alt=""
        />
      </div>
      <ButtonMain
        color={false}
        font={'bold'}
        onPress={() => console.log('hello')}
        text={'Upgrade Plan'}
      />
    </>
  );
}

export default GiftsCard;
