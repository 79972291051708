import { Box } from '@mui/material';
import React from 'react';

function StudentInformation({
  role,
  studentImg,
  studentName,
  grade,
  schoolName,
  selected,
  onClick,
  gemsToken,
  show,
  clickDelete,
}: {
  studentImg?: any;
  studentName: string;
  grade: string;
  schoolName: string;
  selected: boolean;
  onClick?: any;
  role?: string;
  gemsToken?: string;
  show?: any;
  clickDelete?: any;
}) {
  return (
    <Box
      sx={{ backgroundColor: selected ? '#E9F6F3' : 'white', }}
      className={
        show
          ? 'rounded-lg  border border-[#20A58A] lg:w-[90%] w-[100%] mr-1 mt-2'
          : 'rounded-lg  border border-[#20A58A] h-[60px] mr-1'
      }
    >
      <div
        onClick={onClick}
        className="flex items-center justify-betweenmobile mt-2 mx-2 "
      >
        {role === 'student' ? (
          <div className="rounded-3xl p-px bg-gradient-to-b from-[#4CBFA7] to-[#368776]">
            <div className="bg-gray-50  rounded-[calc(1.5rem-1px)]">
              <img
                alt=""
                src={studentImg}
                className="lg:h-10 h-6 lg:w-10 w-6 rounded-full"
              />
            </div>
          </div>
        ) : null}
        <div className="ml-2">
          <p className="font-semibold text-xs">{studentName}</p>
          <p className="font-normal	 text-[10px] text-[#666666]">{grade}</p>
          <p className="font-normal	 text-[10px] text-[#666666]">{schoolName}</p>
        </div>
        <div role="button" onClick={clickDelete}>
          <img
            alt=""
            src={require('../../../../assets/Parent/delete.png')}
            className="h-5 w-5 -mt-6"
          />
        </div>
      </div>
      {!show ? (
        <div className="mb-2"></div>
      ) : (
        <div className="flex items-center mb-3 mt-2 mx-3 py-1 justify-center bg-[#EBF5FB]">
          <img
          alt=''
            src={require('../../../../assets/Parent/diamond.png')}
            className="h-3 w-3"
          />
          <img
          alt=''
            src={require('../../../../assets/Parent/dollar.png')}
            className="h-3 w-2 ml-[1px]"
          />
        </div>
      )}
    </Box>
  );
}

export default StudentInformation;
