import { Typography } from '@mui/material';
import React from 'react';

function AcademicUnlock({onClick}:{onClick?:any}) {
  return (
    <div className='flex items-center justify-center mt-3'>
    <div className="mt-2 ">
      <Typography color={'#072D20'} sx={{ fontSize: 12, fontWeight: 700 }}>
        📚 Unlock State-Aligned Standards by Topic!
      </Typography>
      <Typography color={'#072D20'} sx={{ fontSize: 12, fontWeight: 400 }}>
        Free accounts have access to Math Standards—start exploring today!
      </Typography>
      <Typography color={'#072D20'} sx={{ fontSize: 12, fontWeight: 400 }}>
        ✅ Ensure full alignment with state requirements.
      </Typography>
      <Typography color={'#072D20'} sx={{ fontSize: 12, fontWeight: 400 }}>
        ✅ Plan comprehensive learning pathways with confidence.
      </Typography>
      <div role='button'
      onClick={onClick}
      >
      <Typography
        color={'#20A58A'}
        // display="inline"
        sx={{
          fontSize: 14,
          fontWeight: 700,
          textAlign: 'center',
          textDecoration: 'underline',
          marginTop: 3,
          marginBottom: 2,
        }}
      >
        🎯 View Math Standards Now
      </Typography>
      </div>
    </div>
    </div>
  );
}

export default AcademicUnlock;
