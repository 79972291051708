import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Progresss({
  stroke,
  text,
  style,
  value,
}: {
  stroke: string;
  text: string;
  style: any;
  value: any;
}) {
  return (
    <div style={style ? style : { width: 45, height: 45 }}>
      <CircularProgressbar
        value={value}
        text={text}
        styles={{
          root: {
            width: '100%',
          },
          path: {
            stroke: stroke,
            strokeLinecap: 'round',
          },
          trail: {
            stroke: '#d6d6d6',
          },
          text: {
            stroke: '#344054',
            fontWeight: 300,
          },
        }}
      />
    </div>
  );
}

export default Progresss;
