import React, { useEffect } from 'react';

import { LoadingOverlay, Modal, Select } from '@mantine/core';
import CloseButton from 'components/Modal/components/CloseButton';
import useModal from 'components/Modal/hook/useModal';
import { Add, Minus } from 'iconsax-react';

import CustomButton from 'components/CustomButton';
import { useGetSubscriptionsQuery } from 'store/apis/payment';
import { Plan } from 'store/apis/payment/types';
import { useGetMyProfileQuery } from 'store/apis/profile';

export default function PaymentDetails() {
  const { closeModal, isOpen, modal } = useModal<{
    plan: Plan;
    onConfirm: (plans: { paymentPlanId: string; quantity: number }[]) => void;
  }>();

  const { data, isLoading } = useGetSubscriptionsQuery();
  const [selectedPlan, setSelectedPlan] = React.useState<Plan | null>(null);
  const [additionalUsers, setAdditionalUsers] = React.useState(0);

  const { data: userData } = useGetMyProfileQuery();

  const [selectedAddon, setSelectedAddon] = React.useState<
    | (Plan & {
        quantity: number;
      })
    | null
  >(null);

  const filteredData = React.useMemo(() => {
    if (data) {
      const dataWithoutAddons = data?.data?.filter(
        (item) => item.packageType !== 'ADD_ON',
      );
      if (userData) {
        const subscriptionIds = userData?.data?.activeSubscriptions?.map(
          (prod) => prod.product?.id,
        );

        if (subscriptionIds) {
          return dataWithoutAddons
            .filter((item) => !subscriptionIds.includes(item.stripeProductId))
            .map((item) => ({
              label: item.name,
              value: item.id,
            }));
        }
      }
      return dataWithoutAddons.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
    return [];
  }, [data, userData]);

  const addonsData = React.useMemo(() => {
    return data?.data?.find(
      (item) =>
        item.packageType === 'ADD_ON' &&
        item.interval === selectedPlan?.interval,
    );
  }, [data, selectedPlan?.interval]);

  useEffect(() => {
    if (modal?.props?.plan) {
      setSelectedPlan(modal?.props?.plan);
    }
  }, [modal?.props?.plan]);

  const additionalPrice =
    selectedPlan?.pricingTier?.tiers?.find((tier) => tier?.up_to === 'inf')
      ?.price ?? 0;

  const showAdditionalUsers = selectedPlan?.interval !== 'ONE_TIME';

  const additionalUserPrice = showAdditionalUsers ? additionalPrice : 0;

  const totalAmount = React.useMemo(() => {
    return (
      (selectedPlan?.price ?? 0) +
      additionalUsers * additionalUserPrice +
      (selectedAddon?.price ?? 0)
    );
  }, [
    selectedPlan?.price,
    additionalUsers,
    additionalUserPrice,
    selectedAddon?.price,
  ]);

  const onConfirm = () => {
    const plans = [];
    if (selectedPlan?.id) {
      plans.push({
        paymentPlanId: selectedPlan.id,
        quantity: additionalUsers + 1,
      });
    }
    if (selectedAddon) {
      plans.push({
        paymentPlanId: selectedAddon.id,
        quantity: selectedAddon.quantity,
      });
    }
    modal?.props?.onConfirm(plans);
  };

  const getInterval = (interval: string | undefined) => {
    switch (interval) {
      case 'MONTHLY':
        return '1 Month';
      case 'YEARLY':
        return '1 Year';
      default:
        return 'One Time';
    }
  };

  return (
    <Modal
      opened={isOpen('PaymentDetails')}
      onClose={closeModal}
      withCloseButton={false}
      size={'xl'}
      centered
      classNames={{
        body: 'p-4',
      }}
      radius={'lg'}
    >
      <div className="p-2 space-y-4">
        <div className="space-y-4">
          <h1 className="text-[#1E1E1E] font-bold text-xl lg:text-2xl 2xl:text-3xl ">
            Build your G3MS Plan
          </h1>
          <p className=" text-[#747474] font-normal">
            Choose a subscription that fits your needs and add students for even
            more flexibility.
          </p>
          <CloseButton />
        </div>
        <div className="border border-[#DCDCDC] rounded-2xl overflow-hidden">
          <div className="p-4 bg-[#E9F6F3]">
            <h2 className="text-[#1E1E1E] font-bold text-sm lg:text-lg 2xl:text-base ">
              Subscription summary
            </h2>
          </div>

          <div className="p-4 space-y-4">
            <div className="space-y-4">
              <div className="grid grid-cols-[1fr,auto] items-center flex-wrap gap-4">
                <div className="max-w-[20rem]">
                  <Select
                    data={filteredData}
                    placeholder="Select Plan"
                    value={selectedPlan?.id}
                    onChange={(value) => {
                      if (value === null) return;
                      if (value === selectedPlan?.id) {
                        return;
                      }
                      setSelectedPlan(
                        data?.data.find((item) => item.id === value) ?? null,
                      );
                      setAdditionalUsers(0);
                      setSelectedAddon(null);
                    }}
                    className="border border-[#DCDCDC] rounded-lg focus:ring-[#C44297] focus:ring-2 focus:outline-none focus:border-transparent"
                    size="md"
                  />
                </div>

                <span className="text-[#1E1E1E] font-bold text-sm lg:text-lg xl:text-xl 2xl:text-2xl">
                  ${selectedPlan?.price}
                </span>
              </div>
              <hr />
              {showAdditionalUsers && (
                <div className="grid grid-cols-[1fr,auto] items-center flex-wrap gap-4">
                  <div className="max-w-[20rem] flex items-center justify-between gap-4">
                    <p className="text-[#1E1E1E] font-bold text-sm lg:text-lg 2xl:text-base">
                      Additional Users
                    </p>
                    <div className="flex items-center justify-between gap-2 border border-[#D5D7DA] rounded-lg p-1 max-w-[6rem]">
                      <button
                        className="border border-[#D5D7DA] rounded-lg size-6 grid place-items-center"
                        onClick={() => {
                          if (additionalUsers - 1 >= 0) {
                            setAdditionalUsers(additionalUsers - 1);
                          } else {
                            setAdditionalUsers(0);
                          }
                        }}
                      >
                        <Minus className="size-4" />
                      </button>
                      <span className="text-xs lg:text-sm font-semibold text-[#131515]">
                        {additionalUsers}
                      </span>
                      <button
                        className="border border-[#D5D7DA] rounded-lg size-6 grid place-items-center"
                        onClick={() => {
                          if (
                            selectedPlan?.studentLimit &&
                            additionalUsers + 1 > selectedPlan?.studentLimit
                          ) {
                            return;
                          }
                          setAdditionalUsers(additionalUsers + 1);
                        }}
                      >
                        <Add className="size-4" />
                      </button>
                    </div>
                  </div>

                  <span className="text-[#131515] font-medium text-xs lg:text-sm xl:text-lg 2xl:text-xl">
                    ${(additionalUsers * (additionalUserPrice ?? 0)).toFixed(2)}
                  </span>
                </div>
              )}

              <hr />

              {/*  {addonsData && selectedAddon && (
                <div className="grid grid-cols-[1fr,auto] items-center flex-wrap gap-4 pb-4">
                  <div className="max-w-[20rem] flex items-center justify-between gap-4">
                    <p className="text-[#1E1E1E] font-bold text-sm lg:text-lg 2xl:text-base">
                      Add-On Yearly
                    </p>
                  </div>
                  <div className="">
                    <span className="text-[#131515] font-medium text-xs lg:text-sm xl:text-lg 2xl:text-xl">
                      ${selectedAddon?.price}
                    </span>
                  </div>
                </div>
              )} */}
            </div>

            {/*     {addonsData && (
              <div className="border border-[#DCDCDC] rounded-2xl overflow-hidden">
                <div className="px-4 py-3 bg-[#E9F6F3]">
                  <h2 className="text-[#1E1E1E] font-bold text-sm lg:text-lg 2xl:text-base ">
                    {addonsData?.name}
                  </h2>
                </div>
                <div className="p-4 space-y-4">
                  <div className="border border-[#DCDCDC] grid grid-cols-[1fr,auto] gap-4 rounded-2xl overflow-hidden items-center">
                    <p className="text-[#292929]  text-xs sm:text-sm lg:text-base px-4 lowercase">
                      ${addonsData?.price}/{addonsData?.interval}
                    </p>

                    <button
                      className="border border-[#C44297] font-bold py-4 px-8 rounded-2xl
                     hover:bg-[#C44297] hover:text-white transition-colors duration-200
                    "
                      onClick={() => {
                        if (selectedAddon) {
                          setSelectedAddon(null);
                        } else {
                          setSelectedAddon({
                            quantity: 1,
                            ...addonsData,
                          });
                        }
                      }}
                    >
                      {selectedAddon ? 'Remove' : 'Add'}
                    </button>
                  </div>
                  <div className="space-y-1">
                    <p className="text-[#979C9E] text-xs lg:text-sm">
                      {addonsData?.description}
                    </p>
                    <p className="text-[#979C9E] text-xs lg:text-sm ">
                      Features:
                    </p>
                    <ul className="text-[#979C9E] text-xs lg:text-sm list-disc pl-4">
                      {addonsData?.planFeatures?.map((feature, index) => (
                        <li key={index}>{feature.name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )} */}

            <div className="space-y-2 py-2">
              <div className="flex justify-between items-center gap-4 flex-wrap text-[#979C9E]">
                <span>Total Payment</span>
                <span>${totalAmount?.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center gap-4 flex-wrap text-[#979C9E] capitalize">
                <span>Total Duration</span>
                <span className="capitalize">
                  {getInterval(selectedPlan?.interval)}
                </span>
              </div>
              <div className="flex justify-between items-center gap-4 flex-wrap text-[#131515] capitalize font-semibold">
                <span>Total Amount</span>
                <span className="capitalize">${totalAmount?.toFixed(2)}</span>
              </div>
            </div>
            <CustomButton
              variant={'primary'}
              className="max-w-full w-full mt-4"
              onClick={onConfirm}
            >
              Confirm
            </CustomButton>
          </div>
        </div>
      </div>
      <LoadingOverlay visible={isLoading} />
    </Modal>
  );
}
