import { Box, Typography } from '@mui/material';
import React from 'react';

function SocialMatrics({
  mood,
  follow,
  others,
  src,
  name,
  role,
  values,
}: {
  mood?: boolean;
  follow?: boolean;
  others?: boolean;
  src?: any;
  name: string;
  role: string;
  values: any;
}) {
  return (
    <Box className="border rounded-xl border-[#E9EAEB] lg:w-[20%] lg:h-[70px]">
      <Typography
        color={'#535862'}
        sx={{
          fontWeight: 600,
          fontSize: 10,
          marginLeft: 2,
          marginTop: 1.5,
          marginBottom: 2,
        }}
      >
        {name}
      </Typography>
      {mood ? (
        <div className="flex items-center justify-between mx-1 px-1">
          <img
          alt=''
            src={require('../../../../assets/Parent/smiley.png')}
            className="h-6 w-6 ml-1 "
          />
          <Typography
            sx={{ fontWeight: 600, fontSize: 16,marginRight:1 }}
            color={'#101828'}
          >
           {values || "Happy"} 
          </Typography>
        </div>
      ) : null}
      {follow ? (
        <div>
          <div className="flex items-center justify-between px-1">
            <Typography sx={{ fontWeight: 600, fontSize: 12 }}>
              {values?.students || "0"}
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: 10 }}
              color={'#535862'}
            >
              Students
            </Typography>
          </div>
          <div className="flex items-center justify-between px-1 mb-1">
            <Typography sx={{ fontWeight: 600, fontSize: 12 }}>
              {values?.tutor || "0"}
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: 10 }}
              color={'#535862'}
            >
              Teachers/Tutor
            </Typography>
          </div>
        </div>
      ) : null}
      {others ? (
        <div className="flex items-center justify-between px-2 mb-2">
          <Typography sx={{ fontWeight: 600, fontSize: 15, marginTop: 1 }}>
            {values || "0"}
          </Typography>
          <img alt='' src={src} className="h-8 w-8" />
        </div>
      ) : null}
    </Box>
  );
}

export default SocialMatrics;