import { Box, Typography } from '@mui/material';
import React from 'react';
import ButtonMain from '../ButtonMain';
import BenifitsText from '../FreePlan/BenifitsText';
import Progresss from '../Progress/Progresss';

function UpgradeCard({
  from,
  stroke,
  styl,
  fw,
  onPress,
}: {
  stroke: string;
  styl: any;
  fw: string;
  from: string;
  onPress: any;
}) {
  return (
    <Box
      className={
        from == 'modal'
          ? ' mr-3 rounded-lg pb-2 '
          : stroke == '#20A58A'
          ? ' border border-[#EAECF0] mr-3 rounded-lg'
          : ' bg-[#F9FAFB] mr-3 rounded-lg pb-2 pt-2'
      }
    >
      <div className="flex items-center   mt-2">
        <div className="ml-3">
          <Progresss value={66} style={false} text={'7 Days'} stroke={stroke} />
        </div>
        <Typography sx={styl} fontFamily="Inter" color="#000000">
          ⏳ Your Free Trial Is Ending Soon! ⏳
        </Typography>
      </div>
      <div className={stroke == '#20A58A' ? 'mt-4' : 'mt-2'}>
        <BenifitsText
          fw={fw}
          classNameM={stroke == '#20A58A' ? 'mt-2' : 'mt-1'}
          classNameD={stroke == '#20A58A' ? 'text-[#475467]' : 'text-[#000000]'}
          color={stroke == '#20A58A' ? '#475467' : '#000000'}
          text={'Keep redeeming tokens for gift cards and rewards.'}
        />
        <BenifitsText
          fw={'400'}
          classNameM={stroke == '#20A58A' ? 'mt-2' : 'mt-1'}
          classNameD={stroke == '#20A58A' ? 'text-[#475467]' : 'text-[#000000]'}
          color={stroke == '#20A58A' ? '#475467' : '#000000'}
          text={
            'Continue using the AI Tutor for homework help and learning support.'
          }
        />
        <BenifitsText
          fw={'400'}
          classNameM={stroke == '#20A58A' ? 'mt-2' : 'mt-1'}
          classNameD={stroke == '#20A58A' ? 'text-[#475467]' : 'text-[#000000]'}
          color={stroke == '#20A58A' ? '#475467' : '#000000'}
          text={
            'Stay ahead with full access to ELA, Science, Social Studies, and test prep.'
          }
        />
      </div>
      {from === 'modal' ? null : stroke == '#20A58A' ? (
        <ButtonMain
          color={false}
          font={'bold'}
          onPress={onPress}
          text={'Upgrade Plan'}
        />
      ) : (
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: '500',
            marginLeft: 3,
            marginTop: 3,
          }}
          fontFamily="Inter"
          color="#C44297"
        >
          Upgrade plan
        </Typography>
      )}
    </Box>
  );
}

export default UpgradeCard;
