import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Progresss from '../Progress/Progresss';
import CustomizedCheckboxAcademic from '../CheckBoxes/CustomizedCheckboxAcademic';
import ButtonMain from '../ButtonMain';
import CustomizedCheckboxSkillContent from '../CheckBoxes/CustomizedCheckboxSkillContent';
import AddStudentButtons from '../AddStudent/AddStudentButtons';
import AddStudentComponent from '../AddStudent/AddStudentComponent';
import UpgradeToEducator from '../UpgradePlan/UpgradeToEducator';
import BenifitsText from '../FreePlan/BenifitsText';
import UpgradeCard from '../UpgradePlan/UpgradeCard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function ModalFreeParents({
  onClose, 
  open,
  upgradePlan,
  skip,
}: {
  onClose: any;
  open: any;
  upgradePlan: any;
  skip: any;
}) {
  const [view, setView] = React.useState(1);
  const val =
    view == 1 ? 20 : view == 2 ? 40 : view == 3 ? 60 : view == 4 ? 60 : null;
  const text =
    view == 1
      ? '1 / 3'
      : view == 2
      ? '2 / 3'
      : view == 3
      ? '3 / 3'
      : view == 4
      ? '3 / 3'
      : view == 5
      ? '3 / 3'
      : '';
  const heading =
    view == 1
      ? '🎉 Welcome to G3MS, [Parent’s Name]!'
      : view == 2
      ? 'Add a student?'
      : view == 3
      ? '🎉 Child Name was added to G3MS.'
      : view == 4
      ? ''
      : view == 5
      ? 'Add another Parent'
      : '';
  const benifits = [
    {
      text: '✅ Access state-aligned Math lessons.',
    },
    {
      text: '✅ Earn tokens to customize their avatar.',
    },
    {
      text: '✅ Use the camera for interactive challenges.',
    },
  ];
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="innerboxShadow w-96"
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: view == 3 ? 380 : view == 6 ? 300 : 440,
            //width: 700,
            padding: 3,
            backgroundColor: '#fff',
            boxShadow: 'shadow-inner-2xl',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <div
            onClick={onClose}
            className=" flex md:flex lg:flex justify-end pr-4 pt-4"
          >
            <img
              src={require('../../../../assets/Parent/cross.png')}
              style={{ height: 8, width: 8 }}
            />
          </div>
          {view == 6 ? null : (
            <div className={' flex mt-3 ml-4'}>
              <div>
                <Progresss
                  value={val}
                  style={{ height: 50, width: 50 }}
                  text={text}
                  stroke="#C73E9D"
                />
              </div>
              <div className="mx-3">
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 700,
                    //textAlign: 'center',
                  }}
                  color={'#2C3E50'}
                >
                  {heading}
                </Typography>
                {view == 1 || view == 2 ? (
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: 11,
                      //textAlign: 'center',
                      marginTop: 1,
                      //paddingX: view == 6 ? 4 : 2,
                    }}
                    color={'#000000'}
                  >
                    {view == 1 ? (
                      'Thank you for joining G3MS to support child’s learning journey. Here’s what they can do with the Free Plan:'
                    ) : (
                      <div className={'mt-3'}></div>
                    )}
                  </Typography>
                ) : null}
              </div>
            </div>
          )}
          {view == 1 ? (
            <div className="mt-4 ml-4">
              {benifits.map((item) => (
                <BenifitsText
                  fw={'400'}
                  classNameM="mt-"
                  color={'#000000'}
                  classNameD="text-[#000000]"
                  text={item.text}
                />
              ))}
            </div>
          ) : view == 2 ? (
            <AddStudentComponent type="student" />
          ) : view == 3 ? (
            <div>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  marginTop: 2,
                  marginLeft: 6,
                }}
              >
                Would you like to add another kid?
              </Typography>
              <AddStudentButtons
                bg2={false}
                bg={false}
                title1="No"
                title2="Yes"
                wd={'45%'}
                onPressNo={() => setView(6)}
                onPressYes={() => setView(4)}
              />
            </div>
          ) : view == 4 ? (
            <div>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  marginTop: 2,
                  marginLeft: 6,
                }}
              >
                FREE Plan is only include 1 kid, Please upgrade you plan to add
                more student.
              </Typography>
              <AddStudentButtons
                bg2={'#20A58A'}
                bg={'#C73E9D'}
                title1="Upgrade Plan"
                title2="Skip, For now"
                wd={'45%'}
                onPressNo={upgradePlan}
                onPressYes={skip}
              />
            </div>
          ) : view == 5 ? (
            <div>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  marginTop: 2,
                  marginLeft: 6,
                }}
              >
                Would you like to add another parent to manage Child account?
              </Typography>
              <AddStudentComponent type="parent" />
            </div>
          ) : view == 6 ? (
            <UpgradeCard
              onPress={() => console.log('hello')}
              from={'modal'}
              fw={'500'}
              styl={{
                fontWeight: 700,
                fontSize: 10,
                marginLeft: 3,
                marginRight: 2,
              }}
              stroke={'#C44297'}
            />
          ) : null}
          {view == 1 || view == 2 ? (
            <div className="mt-5">
              <ButtonMain
                color="#C73E9D"
                font={'400'}
                onPress={() => setView(view + 1)}
                text={view === 2 ? 'Add Student' : 'Next'}
              />
            </div>
          ) : view == 5 ? (
            <div className="mt-5">
              <AddStudentButtons
                bg2={false}
                bg={'#C73E9D'}
                title1="Add another Parent"
                title2="Skip"
                wd={'40%'}
                onPressNo={() => console.log('parent added')}
                onPressYes={() => setView(6)}
              />
            </div>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}

export default ModalFreeParents;
