
import Modal from 'components/Modal/components/Modal';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';
import success from './assets/success.svg';
import CustomButton from 'components/CustomButton';

export default function QrCodeSuccessModal() {
  const { closeModal, isOpen, modal } = useModal<{ onContinue: () => void }>();

  return (
    <Modal isOpen={isOpen('QrCodeSuccessModal')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_30rem]">
        <Modal.CloseButton />
        <div className="flex flex-col gap-8 py-4">
          <img
            src={success}
            alt="success"
            className="size-8 md:size-10 lg:size-12 mx-auto"
          />

          <h1
            className={cn(
              '!font-semibold text-xl xl:!text-2xl 2xl:text-3xl text-center text-[#131515] mx-auto',
            )}
          >
            🎉 QR Code Received! 🎉
          </h1>

          <p
            className={cn(
              'text-base xl:text-lg text-center text-black max-w-[50ch] mx-auto',
            )}
          >
            Thank you for scanning your QR code! We’ve successfully recognized
            your code. Now, please set up your account to start enjoying all the
            benefits of G3MS. Follow the steps to complete your registration,
            and unlock exciting features tailored just for you!
          </p>

          <CustomButton
            className="max-w-none w-full"
            variant="primary"
            onClick={modal?.props?.onContinue}
          >
            Continue
          </CustomButton>
        </div>
      </Modal.Content>
    </Modal>
  );
}
