import { Typography } from '@mui/material';
import cn from 'utils/cn';

function BenifitsText({
  text,
  classNameD,
  classNameM,
  color,
  fw
}: {
  text: string;
  classNameD: string;
  classNameM: any;
  color: any;
  fw:string
}) {
  return (
    <div className={cn('flex items-start mx-2', classNameM)}>
      <div
        className={cn('font-bold text-2xl', classNameD)}
        style={{ lineHeight: '1px' }}
      >
        .
      </div>
      <Typography
        sx={{
          fontWeight: fw,
          fontSize: 11,
          paddingLeft: 1,
          lineHeight: 'inherit',
        }}
        fontFamily="Inter"
        color={color}
      >
        {text}
      </Typography>
    </div>
  );
}

export default BenifitsText;
