import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import CustomizedCheckbox from './CustomizedCheckbox';
import { typography } from '@mui/system';

export default function CustomizedCheckboxSkillContent() {
  const [state, setState] = React.useState({
    invest: false,
    relationShip: false,
    socialFun: false,
    moneyManagement: false,
    web3: false,
    aiWorkforce: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const {
    invest,
    relationShip,
    socialFun,
    moneyManagement,
    web3,
    aiWorkforce,
  } = state;

  return (
    <Box sx={{ display: '' }} className=" px-5 ">
      <div className=" flex ">
        <div className=" w-2/5 ">
          <FormControlLabel
            control={
              <CustomizedCheckbox
                size="small"
                checked={invest}
                onChange={handleChange}
                name="invest"
              />
            }
            label={
              <span style={{ fontSize: 13, fontWeight: 500 }}>Investing</span>
            }
          />
        </div>
        <div className=" w-3/5 ">
          <FormControlLabel
            control={
              <CustomizedCheckbox
                size="small"
                checked={relationShip}
                onChange={handleChange}
                name="relationShip"
              />
            }
            label={
              <span style={{ fontSize: 13, fontWeight: 500 }}>
                Building Relationships
              </span>
            }
          />
        </div>
      </div>
      <div className=" flex ">
        <div className=" w-2/5 ">
          <FormControlLabel
            control={
              <CustomizedCheckbox
                size="small"
                checked={socialFun}
                onChange={handleChange}
                name="socialFun"
              />
            }
            label={
              <span style={{ fontSize: 13, fontWeight: 500 }}>Social Fun!</span>
            }
          />
        </div>
        <div className=" w-3/5 ">
          <FormControlLabel
            control={
              <CustomizedCheckbox
                size="small"
                checked={moneyManagement}
                onChange={handleChange}
                name="moneyManagement"
              />
            }
            label={
              <span style={{ fontSize: 13, fontWeight: 500 }}>
                Money Management
              </span>
            }
          />
        </div>
      </div>
      <div className=" flex ">
        <div className=" w-2/5 ">
          <FormControlLabel
            control={
              <CustomizedCheckbox
                size="small"
                checked={web3}
                onChange={handleChange}
                name="web3"
              />
            }
            label={<span style={{ fontSize: 13, fontWeight: 500 }}>Web 3</span>}
          />
        </div>
        <div className=" w-3/5 ">
          <FormControlLabel
            control={
              <CustomizedCheckbox
                size="small"
                checked={aiWorkforce}
                onChange={handleChange}
                name="aiWorkforce"
              />
            }
            label={
              <span style={{ fontSize: 13, fontWeight: 500 }}>
                AI workforce
              </span>
            }
          />
        </div>
      </div>
    </Box>
  );
}
