import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Circle from '@mui/icons-material/Circle';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    //top: -1,
    left: 'calc(-50% + 7px)',
    right: 'calc(50% + 7px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#C44297',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#C44297',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 0.9,
    border: 0,
    backgroundColor: '#C44297',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  //backgroundColor: '#ccc',
  zIndex: 1,
  color: '#C44297',
  width: 15,
  height: 15,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: 1,
  borderColor: '#C44297',
  marginTop: 5,
  labelContainer: {
    "&$alternativeLabel": {
      marginTop: 0
    }},
  //...theme.applyStyles('dark', {
  //  backgroundColor: theme.palette.grey[700],
  // }),
  variants: [
    {
      props: ({ ownerState }: { ownerState: any }) => ownerState.active,
      style: {
        backgroundColor: '#fff',

        // backgroundImage:
        //  'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }: { ownerState: any }) => ownerState.completed,
      style: {
        backgroundColor: '#fff',
        // backgroundImage:
        // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      },
    },
  ],
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement<unknown> } = {
    1: <Circle style={{ fontSize: '4px' }} />,
    2: <Circle style={{ fontSize: '4px' }} />,
    3: <Circle style={{ fontSize: '4px' }} />,
    4: <Circle style={{ fontSize: '4px' }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className=''
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  'Add Your First Student',
  'Explore AI-Powered Features',
  'Add another Parent to manage student',
  'Start Earning Rewards',
];


export default function CustomizedSteppers() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={1}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, i) => (
          <Step key={label}
           sx={{
          '& .MuiStepLabel-root .Mui-completed': {
             // circle color (COMPLETED)
             marginTop:0,
             color:'#6F6F6F',
             fontSize:'10px',
             lineHeight:'12px'
          },
          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
            {
              // Just text label (COMPLETED)
                         marginTop:0,
             color:'#6F6F6F',
             fontSize:'10px',
             lineHeight:'12px'
            },'& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
            {
              // Just text label (COMPLETED)
                       marginTop:0,
             color:'#6F6F6F',
             fontSize:'10px',
             lineHeight:'12px'
            },'& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
            {
              // Just text label (COMPLETED)
                         marginTop:0,
             color:'#6F6F6F',
             fontSize:'10px',
             lineHeight:'12px'
            },
       
        }}
          
          >
            
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
            >
              <div>Step {i + 1}</div>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
