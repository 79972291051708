import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { setScreenLoading } from 'store/Slice/userSlice';
import {
  setTopicList,
  setSubTopicList,
  setGradeList,
  setSkillList,
  setVideoList,
  setAssetList,
  setMuxUploadUrl,
  setVideoById,
  setTeacherList,
  setLoading,
  setQuarterList,
} from 'store/Slice/videoSlice';
import { getSuperAdminVideos } from './dashboardThunk';

export const getTeacherList = createAsyncThunk(
  'getTeacherList',
  async (_, { dispatch }) => {
    let url = `/api/v1/tutors?limit=1000&page=1`;
    try {
      const response: any = await API.get(url);
      dispatch(setTeacherList(response.data.data));
      return response;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getTopicList = createAsyncThunk(
  'getTopicList',
  async (_, { dispatch }) => {
    let url = `/api/v1/topics?limit=1000&page=1`;
    try {
      const response: any = await API.get(url);

      dispatch(setTopicList(response.data.data));
      return response; 
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getSubTopicList = createAsyncThunk(
  'getSubTopicList',
  async ({ topic }: any, { dispatch }) => {
    let url = `/api/v1/topic/${topic?.id}/sub-topic`;
    try {
      const response: any = await API.get(url);
      // console.log('API response --> ', response);

      dispatch(setSubTopicList(response.data.data));
      // return tempSubTopic;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getSkillList = createAsyncThunk(
  'getSkillList',
  async ({ subTopic }: any, { dispatch }) => {
    let url = `/api/v1/sub-topic/${subTopic?.id}/skill`;

    try {
      const response: any = await API.get(url);
      dispatch(setSkillList(response.data.data));
      // return tempSkills;
    } catch (err: any) {
      dispatch(setSkillList([]));
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getGradeList = createAsyncThunk(
  'getGradeList',
  async (_, { dispatch }) => {
    let url = `/api/v1/grades`;
    try {
      const response: any = await API.get(url);

      dispatch(setGradeList(response.data.data));
      // return response;
    } catch (err: any) {
      dispatch(setGradeList([]));
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const saveUploadVideo = createAsyncThunk(
  'uploadVideo',
  async (formData: any, { dispatch }) => {
    console.log('formDataformData', formData);
    let url =
      formData?.id && formData.isAcademic
        ? `/api/v1/videos/${formData?.id}`
        : formData?.id && !formData.isAcademic
        ? `/api/v1/videos/${formData?.id}/social-fun`
        : !formData?.id && !formData.isAcademic
        ? `/api/v1/videos/social-fun`
        : `/api/v1/videos`;
    try {
      dispatch(setLoading(true));
      if (formData?.id) {
        let _request = {
          title: formData?.title,
          districtIds: formData?.districtIds,
          schoolIds: formData?.schoolIds,
          topicId: formData?.topicId,
          subTopicId: formData?.subTopicId,
          domainId: formData?.domainId,
          skillId: formData?.skillId,
          userId: formData?.userId,
          gradeIds: formData?.gradeIds,
          assetId: formData?.assetId,
          playbackId: formData?.playbackId,
          uploadId: formData?.uploadId,
        };
        if (!formData.isAcademic) {
          delete _request?.subTopicId;
          delete _request?.skillId;
          delete _request?.domainId;
        }
        const response = await API.patch(url, _request);
        dispatch(notificationSuccess('Video detail updated successfully'));
      } else {
        let _request = {
          title: formData?.title,
          districtIds: formData?.districtIds,
          domainId: formData?.domainId,
          schoolIds: formData?.schoolIds,
          topicId: formData?.topicId,
          subTopicId: formData?.subTopicId,
          skillId: formData?.skillId,
          userId: formData?.userId,
          assetId: formData?.assetId,
          gradeIds: formData?.gradeIds,
          uploadId: formData?.uploadId,
        };
        if (!formData.isAcademic) {
          delete _request?.subTopicId;
          delete _request?.skillId;
          delete _request?.domainId;
        }

        if (formData?.assetId) {
          delete _request?.uploadId;
        } else {
          delete _request?.assetId;
        }
        await API.post(url, _request);
        dispatch(notificationSuccess('Video uploaded successfully'));
      }
      dispatch(setLoading(false));
      dispatch(
        getVideoList({
          pageSize: 10,
          pageIndex: 0,
          isAcademic: formData.isAcademic,
        }),
      );
    } catch (err: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const changeVideoStatus = createAsyncThunk(
  'changeVideoStatus',
  async (formData: any, { dispatch }) => {
    console.log('formDataformData', formData);
    let url = `/api/v1/videos/change-status`;
    dispatch(setLoading(true));
    try {
      let _request = {
        videoId: formData?.videoId,
        status: formData?.status,
      };

      await API.post(url, _request);
      dispatch(notificationSuccess('Video status updated successfully'));

      dispatch(setLoading(false));
    } catch (err: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getVideoList = createAsyncThunk(
  'getVideoList',
  async (_request: any, { dispatch }) => {
    // dispatch(startLoading());
    dispatch(startLoading());
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 10;
    const topicId = _request?.topicId || '';
    const search = _request?.search || '';
    const isAcademic = _request?.isAcademic || false;
    let url = `/api/v1/videos?page=${
      page + 1
    }&limit=${size}&topicId=${topicId}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      url += `&isAcademic=${isAcademic}`;
      const response: any = await API.get(url);

      dispatch(setVideoList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(setVideoList([]));
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const convertQuarters = (quarterList: any[]) => {
  const sortedData = Object.keys(quarterList)
    .sort()
    .reduce((acc: any, key: any) => {
      acc[key] = quarterList[key];
      return acc;
    }, {});

  return Object.values(sortedData).map((quarter: any) => {
    return {
      quarterId: quarter.quarterId,
      name: quarter.name,
      subTopics: quarter.subTopics,
    };
  });
};
export const getQuarterList = createAsyncThunk(
  'getQuarterList',
  async (_request: any, { dispatch }) => {
    // dispatch(startLoading());
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 10;
    const search = _request?.search || '';
    const grade = _request?.grade || '';
    const topic = _request?.topic || '';
    const id = _request?.id || '';
    let url = `/api/v1/assignment/contents/${id}?grade=${grade}&topic=${topic}&page=${
      page + 1
    }&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      const convertedQuarter: any[] = convertQuarters(
        response.data?.data?.templatesByQuarter,
      );
      dispatch(setQuarterList(convertedQuarter));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(setQuarterList([]));
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getAssetList = createAsyncThunk(
  'getAssetList',
  async (_request: any, { dispatch }) => {
    // dispatch(startLoading());
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `/api/v1/videos/assetIds?page=${page + 1}&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }

      const response: any = await API.get(url);
      console.log(response);
      console.log('hittttttt');
      dispatch(setAssetList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(setAssetList([]));
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getVideoById = createAsyncThunk(
  'getVideoById',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/videos/${id}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setVideoById(response.data.data));
      dispatch(stopLoading());
      return response?.data?.data;
    } catch (err: any) {
      dispatch(setVideoById({}));
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getMuxUploadUrl = createAsyncThunk(
  'get_upload_url',
  async (_, { dispatch }) => {
    let url = `/api/v1/videos/upload_url`;
    try {
      const response: any = await API.get(url);
      // console.log(response.data.data)
      // result({ status: 'true', url: response?.data?.data?.url })
      dispatch(setMuxUploadUrl(response.data.data));
      return response;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const deleteVideo = createAsyncThunk(
  'deleteVideo',
  async ({ id, search, isAcademic }: any, { dispatch }) => {
    let url = `/api/v1/videos/${id}`;
    try {
      await API.delete(url);
      dispatch(
        getVideoList({
          pageSize: 10,
          pageIndex: 0,
          search: search,
          isAcademic: isAcademic,
        }),
      );
      dispatch(notificationSuccess('Video deleted successfully'));
      return true;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);
