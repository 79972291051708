import cn from 'utils/cn';
import styles from './Modal.module.css';



export default function ModalContent({ children, className = '', style = {} }) {
  return (
    <div
      style={style}
      className={cn(styles['modal-content'], className)}
      onPointerDown={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
}
