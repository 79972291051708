import { Button, Box } from '@mui/material';
import ButtonMain from 'pages/parent/components/ButtonMain';
import BenifitsText from 'pages/parent/components/FreePlan/BenifitsText';
import React from 'react';
import Header from './Header';

function EducatorFeature({ onPress }: { onPress: any }) {
  return (
    <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg">
      <div className="ml-2 mt-2">
        <Header text="Explore G3MS Features" />
      </div>
      <div className="grid grid-cols-2 mx-2 mt-2">
        <div>
          <BenifitsText
            fw={'400'}
            classNameM=""
            classNameD="text-[#475467]"
            color={'#475467'}
            text={'✅ Enter Early Adopter Code'}
          />
          <BenifitsText
            fw={'400'}
            classNameM=""
            classNameD="text-[#475467]"
            color={'#475467'}
            text={'✅ Add Your District or Company.'}
          />
          <BenifitsText
            fw={'400'}
            classNameM=""
            classNameD="text-[#475467]"
            color={'#475467'}
            text={'✅ Add Your School'}
          />
        </div>
        <div>
          <BenifitsText
            fw={'400'}
            classNameM=""
            classNameD="text-[#475467]"
            color={'#475467'}
            text={'❌ Add Students.'}
          />
          <BenifitsText
            fw={'400'}
            classNameM=""
            classNameD="text-[#475467]"
            color={'#475467'}
            text={'❌ Set Proficiency Levels.'}
          />
          <BenifitsText
            fw={'400'}
            classNameM=""
            classNameD="text-[#475467]"
            color={'#475467'}
            text={'❌ Assign Your First Lesson.'}
          />
        </div>
      </div>
      <ButtonMain color={false} font={'500'} onPress={onPress} text={'Next'} />
    </Box>
  );
}

export default EducatorFeature;
