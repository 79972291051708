import React from 'react';
import Button from '@mui/material/Button';

function AddStudentButtons({
  onPressYes,
  onPressNo,
  wd,
  title1,
  title2,
  bg,
  bg2,
}: {
  onPressYes: any;
  onPressNo: any;
  wd: any;
  title1: string;
  title2: string;
  bg: any;
  bg2: any;
}) {
  return (
    <div className="w-full flex justify-center space-x-2 mt-6 mb-7">
      <Button
        onClick={onPressNo}
        sx={{
          backgroundColor: bg ? bg : '#F57E77',
          height: 35,
          width: wd,
          color: 'white',
          fontSize: 11,
          fontWeight: 500,
        }}
      >
        {title1}
      </Button>
      <Button
        onClick={onPressYes}
        sx={{
          backgroundColor: bg2 ? bg2 : '#20A58A',
          height: 35,
          width: wd,
          color: 'white',
          fontSize: 11,
          fontWeight: 400,
        }}
      >
        {title2}
      </Button>
    </div>
  );
}

export default AddStudentButtons;
