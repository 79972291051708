import { Box } from '@mui/material';
import React from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function CarouselView() {
  return (
    <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg mt-2">
      <div className="mt-2 ml-2">
        <Header text="Explore Features" />
        <div>
          <Carousel
            className="px-5 py-5 "
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            centerMode={true}
            autoPlay={true}
            swipeable={true}
            infiniteLoop={true}
            autoFocus={false}
            centerSlidePercentage={92}
          >
            <img
              className=""
              src={require('../../../../assets/Parent/Credit.png')}
            />
            <img src={require('../../../../assets/Parent/Credit.png')} />
          </Carousel>
        </div>
      </div>
    </Box>
  );
}

export default CarouselView;
