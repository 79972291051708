import { useEffect, useRef } from 'react';
import CloseButton from './CloseButton';
import styles from './Modal.module.css';
import ModalContent from './ModalContent';
import ModalHeader from './ModalHeader';
import Portal from './Portal';

export default function Modal({
  onClose,
  isOpen,
  children,
  className = '',
  style = {},
  closeOnBackdropClick = true,
  closeOnEscape = true,
}) {
  const backdropRef = useRef(null);

  const handleClose = (e) => {
    e.stopPropagation();
    onClose?.();
  };

  const handleEscape = (e) => {
    if (e.key === 'Escape') {
      handleClose(e);
    }
  };

  useEffect(() => {
    const backdrop = backdropRef.current;
    if (backdrop) {
      //  stop scrollng without moving the screen to the top
      if (isOpen) {
        backdrop.style.display = 'flex';
        backdrop.scrollTop = window.scrollY;
      } else {
        backdrop.style.display = 'none';
        window.scrollTo(0, backdrop.scrollTop);
      }
    }
  }, [isOpen]);

  return (
    <Portal containerId="modal">
      {isOpen && (
        <>
          <div
            style={style}
            className={`${styles['modal-backdrop']}  ${className}`}
            onClick={closeOnBackdropClick ? handleClose : undefined}
            onPointerDown={(e) => e.stopPropagation()}
            onKeyDown={closeOnEscape ? handleEscape : undefined}
            ref={backdropRef}
          ></div>
          {children}
        </>
      )}
    </Portal>
  );
}

Modal.Content = ModalContent;
Modal.Header = ModalHeader;
Modal.CloseButton = CloseButton;
