import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Progresss from '../Progress/Progresss';
import CustomizedCheckboxAcademic from '../CheckBoxes/CustomizedCheckboxAcademic';
import ButtonMain from '../ButtonMain';
import CustomizedCheckboxSkillContent from '../CheckBoxes/CustomizedCheckboxSkillContent';
import AddStudentButtons from '../AddStudent/AddStudentButtons';
import AddStudentComponent from '../AddStudent/AddStudentComponent';
import UpgradeToEducator from '../UpgradePlan/UpgradeToEducator';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function ModalMain({
  onClose,
  open,
  onPressUpgrade,
  keepOldPlan,
}: {
  onClose: any;
  open: any;
  onPressUpgrade: any;
  keepOldPlan: any;
}) {
  const [view, setView] = React.useState(1);
  const val =
    view == 1
      ? 20
      : view == 2
      ? 40
      : view == 3
      ? 60
      : view == 4
      ? 80
      : view == 5
      ? 100
      : view == 6
      ? 60
      : null;
  const text =
    view == 1
      ? '1 / 5'
      : view == 2
      ? '2 / 5'
      : view == 3
      ? '3 / 5'
      : view == 4
      ? '4 / 5'
      : view == 5
      ? '5 / 5'
      : view == 6
      ? '3 / 5'
      : '';
  const heading =
    view == 1
      ? 'Select Academic & Test Prep Content'
      : view == 2
      ? 'Social Emotional & Life skills Content'
      : view == 3
      ? 'Add a student?'
      : view == 4
      ? 'Would you like to add another student ?'
      : view == 5
      ? 'Upgrade to Educator Dashboard'
      : view == 6
      ? 'Add another Parent'
      : '';

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="innerboxShadow  lg:w-2/5"
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: view == 3 || view == 6 ? 430 : view == 5 ? 560 : '30%',
            //width: 700,
            padding: 3,
            backgroundColor: '#fff',
            boxShadow: 'shadow-inner-2xl',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <div
            onClick={onClose}
            className=" flex md:flex lg:flex justify-end pr-4 pt-4"
          >
            <img
              src={require('../../../../assets/Parent/cross.png')}
              style={{ height: 8, width: 8 }}
            />
          </div>
          <div className={view == 6 ? ' flex mt-2 ml-4' : 'mt-2'}>
            <div onClick={onClose} className=" flex justify-center">
              <Progresss
                value={val}
                style={{ height: 30, width: 30 }}
                text={text}
                stroke="#C73E9D"
              />
            </div>

            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 700,
                textAlign: 'center',
                marginTop: view == 6 ? 0 : 2,
                marginLeft: view == 6 ? 3 : 0,
              }}
              color={'#2C3E50'}
            >
              {heading}
            </Typography>
          </div>
          {view == 1 || view == 2 || view == 6 ? (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: view == 6 ? 14 : 10,
                //textAlign: 'center',
                marginTop: view == 6 ? 4 : 1,
                paddingX: view == 6 ? 4 : 3,
              }}
              color={'#000000'}
            >
              {view == 1 ? (
                'Select the subjects and test prep areas to personalize [Child’s Name’]s learning experience and help them reach their full potential!'
              ) : view == 2 ? (
                "Support [Child’s Name]'s personal growth with essential life skills."
              ) : view == 6 ? (
                'Would you like to add another parent to manage {Child} account?'
              ) : (
                <div className={'mt-3'}></div>
              )}
            </Typography>
          ) : null}

          {view == 1 ? (
            <CustomizedCheckboxAcademic />
          ) : view == 2 ? (
            <CustomizedCheckboxSkillContent />
          ) : view == 4 ? (
            <AddStudentButtons
              bg2={false}
              bg={false}
              title1="No"
              title2="Yes"
              wd={'23%'}
              onPressNo={() => setView(6)}
              onPressYes={() => setView(3)}
            />
          ) : view == 3 ? (
            <AddStudentComponent type="student" />
          ) : view == 6 ? (
            <AddStudentComponent type="parent" />
          ) : view == 5 ? (
            <UpgradeToEducator
              upgrade={onPressUpgrade}
              keepOldPlan={keepOldPlan}
            />
          ) : null}
          {view == 1 || view == 2 || view == 3 ? (
            <div className={view == 3 ? 'mt-5' : 'mb-3'}>
              <ButtonMain
                color="#C73E9D"
                font={'400'}
                onPress={() => setView(view + 1)}
                text={view === 3 ? 'Add Student' : 'Next'}
              />
            </div>
          ) : view == 6 ? (
            <div className="mt-5">
              <AddStudentButtons
                bg2={false}
                bg={'#C73E9D'}
                title1="Add another Parent"
                title2="Skip"
                wd={'40%'}
                onPressNo={() => console.log('parent added')}
                onPressYes={() => setView(5)}
              />
            </div>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}

export default ModalMain;
