import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
export interface ResponseData {
    status: String;
    messagecode: String;
    message: String;
    data: any;
  }
  
  interface IntialState {
   classRoomList: any;
    classroomStudentsList:any;
   engagements:any;
   reactions:any;
   totals:any
  }
  const intialState: IntialState = {
    classRoomList:[],
    classroomStudentsList:[],
    engagements:[],
    reactions:{},
   totals:{},
  };
  const ParentEducatorDashboardSlice = createSlice({
    name: 'ParentEducatorDashboardSlice',
    initialState: intialState,
    reducers: {
      setClassRoomList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.classRoomList = action.payload;
      },    
      setClassroomStudentList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.classroomStudentsList = action.payload;
      },  
      setEngagementList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.engagements = action.payload;
      },  
      setReactionList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.reactions = action.payload;
      },  
      setTotals: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.totals = action.payload;
      },  
    },
  });
  export const {
    setClassRoomList,
   setClassroomStudentList,
    setEngagementList,
    setReactionList,
    setTotals
  } = ParentEducatorDashboardSlice.actions;
  
  export default ParentEducatorDashboardSlice.reducer;