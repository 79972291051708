import CustomButton from 'components/CustomButton';
import Modal from 'components/Modal/components/Modal';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';
import sad from './assets/sad.svg';

export default function QrCodeErrorModal() {
  const { closeModal, isOpen, modal } = useModal<{ onResend: () => void }>();

  return (
    <Modal isOpen={isOpen('QrCodeErrorModal')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_40rem]">
        <Modal.CloseButton />
        <div className="flex flex-col gap-8 py-4">
          <img
            src={sad}
            alt="sad"
            className="size-8 md:size-10 lg:size-12 mx-auto"
          />

          <h1
            className={cn(
              '!font-semibold text-xl xl:!text-2xl 2xl:text-3xl text-center text-[#131515] mx-auto',
            )}
          >
            Oops! We couldn’t recognize your QR code.
          </h1>

          <div className="grid gap-4">
            <p className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
              It seems there’s an issue with the code you scanned. Please
              ensure:
            </p>

            <ul className="list-disc list-inside">
              <li className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
                The QR code is clear and within the scanner frame.
              </li>
              <li className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
                You are using the correct QR code for G3MS.
              </li>
            </ul>

            <p className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
              If the issue persists, try again or contact our support team for
              assistance.
            </p>
          </div>

          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <CustomButton
              className="max-w-none w-full"
              onClick={modal?.props?.onResend}
            >
              Contact Support
            </CustomButton>
            <CustomButton
              className="max-w-none w-full"
              variant="primary"
              onClick={closeModal}
            >
              Retry Scanning
            </CustomButton>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
