import { Loader } from '@mantine/core';
import VerifyEmail from 'components/VerifyEmail';
import { useEffect, useState } from 'react';
import {
  Navigate,
  useSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { startLoading } from 'store/Slice/loadingSlice';
import { loginwithToken } from 'store/Thunk/authThunk';
import { useAppDispatch, useAppSelector } from 'store/store';

interface Props {
  children: JSX.Element;
  allowedRoles?: string[];
}

const ProtectedRoute = ({ children, allowedRoles }: Props) => {
  const userData: any = localStorage.getItem('userData');

  const isAuthenticated = JSON.parse(userData);

  // console.log("----------------------", userRole, allowedRoles)
  const dispatch: any = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const schoolId = window.location.pathname?.split('/school/') || [];
  const redirect = searchParams.get('redirectTo');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (searchParams.get('token')) {
        try {
          dispatch(startLoading());
          setIsLoading(true);
          localStorage.clear();
          await dispatch(
            loginwithToken({
              token: searchParams.get('token'),
              navigate,
              schoolId,
            }),
          );
          const token = searchParams.get('token');
          if (token) {
            localStorage.setItem('token', token);
          }
          searchParams.delete('token');
          if (redirect) {
            searchParams.delete('redirectTo');
            navigate(redirect?.[0] === '/' ? redirect : `/${redirect}`);
          } else {
            navigate(pathname);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    })();
  }, [searchParams]);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isLoading]);

  if (!Object.keys(isAuthenticated ? isAuthenticated : {}).length) {
    return <Navigate to={'/login'} replace />;
  } else {
    const userRole = isAuthenticated['type'];
    if (
      allowedRoles &&
      allowedRoles?.length > 0 &&
      allowedRoles.indexOf(userRole) === -1
    ) {
      return <Navigate to={'/'} replace />;
    } else {
      return (
        <>
          <VerifyEmail />
          {children}
          {isLoading && (
            <div className="fixed inset-0 bg-white bg-opacity-50 z-50 flex justify-center items-center">
              <Loader size="md" color="pink" />
              <h2 className="text-pink-500 font-bold text-lg">
                Please wait...
              </h2>
            </div>
          )}
        </>
      );
    }
  }
};

export default ProtectedRoute;
