import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useModal from 'components/Modal/hook/useModal';
import AddStudentButtons from 'pages/parent/components/AddStudent/AddStudentButtons';
import Progresss from 'pages/parent/components/Progress/Progresss';
import { useGetMyProfileQuery } from 'store/apis/profile';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function ParentFreePlanStep3() {
  const { closeModal, openModal, isOpen } = useModal();

  const { data, isLoading } = useGetMyProfileQuery();

  const val = 100;
  const text = '3 / 3';

  const heading = '🎉 Child Name was added to G3MS.';

  return (
    <div>
      <Modal
        open={isOpen('ParentFreePlanStep3')}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="innerboxShadow w-96"
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: view == 3 ? 380 : view == 6 ? 300 : 440,
            //width: 700,
            padding: 3,
            backgroundColor: '#fff',
            boxShadow: 'shadow-inner-2xl',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <div
            onClick={closeModal}
            className=" flex md:flex lg:flex justify-end pr-4 pt-4"
          >
            <img src="/images/cross.png" style={{ height: 8, width: 8 }} />
          </div>

          <div className={' flex mt-3 ml-4'}>
            <div>
              <Progresss
                value={val}
                style={{ height: 50, width: 50 }}
                text={text}
                stroke="#C73E9D"
              />
            </div>
            <div className="mx-3">
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 700,
                  //textAlign: 'center',
                }}
                color={'#2C3E50'}
              >
                {heading}
              </Typography>
            </div>
          </div>

          <div>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 12,
                marginTop: 2,
                marginLeft: 6,
              }}
            >
              Would you like to add another kid?
            </Typography>
            <AddStudentButtons
              bg2={false}
              bg={false}
              title1="No"
              title2="Yes"
              wd={'45%'}
              onPressNo={() => {
                // open step 6
              }}
              onPressYes={() => {
                // open step 4

                if (data?.data?.isUserUpgradedPlan) {
                  openModal('ParentFreePlanStep5');
                  return;
                }

                openModal('ParentFreePlanStep4');
              }}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ParentFreePlanStep3;
