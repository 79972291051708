import { Card } from '@mui/material';
import Header from 'pages/parent/components/Header/Header';
import EducatorDashboard from 'pages/parent/Dashboards/EducatorDashboard';
import React from 'react';

function Dashboard() {
  const userData: any = localStorage.getItem('userData');
  const name :any =JSON.parse(userData).name
  return (
    <Card>
      <Header
      role={"EduOnboarded"} name={name}
      />
    <EducatorDashboard
    role ={'EduOnboarded'}
    />
    </Card>
  );
}

export default Dashboard;