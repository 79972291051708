import { createContext } from 'react';

export type TModal<T> = {
  name: string;
  props: T;
};

export type ModalAction<T> = {
  type: string;
  payload: T;
};

export type TModalState = {
  modal: TModal<any> | undefined;
};

type ModalState = {
  state: TModalState;
  dispatch: (action: ModalAction<any>) => void;
};

const ModalContext = createContext<ModalState>({
  state: {
    modal: undefined,
  },
  dispatch: () => {},
});

export default ModalContext;
