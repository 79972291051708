import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
export interface ResponseData {
    status: String;
    messagecode: String;
    message: String;
    data: any;
  }
  
  interface IntialState {
    childList: any;
    childTotals:any;
    childReactions:any;
    childEngagements:any;
    parentSubtopicList:any;
    studentQRdata:any;
    studentInfo:any;
  }
  const intialState: IntialState = {
    childList:[],
    childTotals:{},
    childReactions:[],
    childEngagements:{},
    parentSubtopicList:[],
    studentQRdata:{},
    studentInfo:{}
  };
  const ParentDashboardSlice = createSlice({
    name: 'parentdashboardSlice',
    initialState: intialState,
    reducers: {
      setChildList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.childList = action.payload;
      },    
      setChildTotals: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.childTotals = action.payload;
      },  
      setChildReactions: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.childReactions = action.payload;
      },
      setChildEngagements: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.childEngagements = action.payload;
      },
      setParentSubTopicList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.parentSubtopicList = action.payload;
      },
      setStudentQR: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.studentQRdata = action.payload;
      },
      setStudentData: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.studentInfo = action.payload;
      },
    },
  });
  export const {
    setChildList,
    setChildTotals,
    setChildReactions,
    setChildEngagements,
    setParentSubTopicList,
    setStudentQR,
    setStudentData
  } = ParentDashboardSlice.actions;
  
  export default ParentDashboardSlice.reducer;