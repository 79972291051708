import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import React, { useEffect } from 'react';
import StudentInformation from './StudentInformation';
import { useDispatch } from 'react-redux';
import {
  getChildTotals,
  getChildReactions,
  getChildEngagements,
  getChildQR,
  getStudentData,
} from 'store/Thunk/ParentDashboardThunk';
import { ThunkDispatch } from '@reduxjs/toolkit';
function StudentList({
  addNew,
  list,
  role,
  show,
}: {
  addNew: any;
  list: any;
  role?: any;
  show?: any;
}) {
  const [selectedStudent, setSelectedStudent] = React.useState(1);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  useEffect(() => {
    if(list?.length>0){
      localStorage.setItem('selectedStudentId', list[0]?.user.id);
      dispatch(getStudentData(list[0]?.user));
      setSelectedStudent(list[0]?.user.id);
      dispatch(getChildTotals('MATH01'));
      dispatch(getChildEngagements());
      dispatch(getChildReactions());
      dispatch(getChildQR());
    }
   
  }, []);
  const onClick = (item: any) => {
    localStorage.setItem('selectedStudentId', item?.user?.id);
    setSelectedStudent(item?.user?.id);
    dispatch(getStudentData(item?.user));
    dispatch(getChildTotals('MATH01'));
    dispatch(getChildEngagements());
    dispatch(getChildReactions());
    dispatch(getChildQR());
  };
  return (
    <Box className="border border-[#EAECF0] lg:ml-3 rounded-xl">
      <div className="flex mt-2 mx-3 justify-between">
        <Typography sx={{ fontWeight: 600, color: '#101828', fontSize: 14 }}>
          Students
        </Typography>
        <div
          className="bg-[#20A58A] h-[32px] w-[32px] rounded-lg "
          onClick={addNew}
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: 'white',
              textAlign: 'center',
              marginTop: 0.5,
            }}
          >
            +
          </Typography>
        </div>
      </div>
{list?.length>0?
  <div
        className="flex items-center"
        style={{
          alignItems: 'center',
          width: '100%',
          overflowX: 'scroll',
          padding: 0,
          display: 'flex',
          flexDirection: 'row',
        }}      
        >
          <List
            dense
            sx={{
              borderRadius: 10,
            }}
            className='lg:block flex items-center'
          
          >
            {list?.map((item: any) => {
              return (
                <div>
                 
                    <ListItem
                      key={item?.user?.id}
                      style={{
                        padding: 0,
                        //height: '50px',
                        // width: '200px',
                        //backgroundColor: 'red',
                      }}
                      disablePadding
                    >
                      <ListItemButton
                        style={{
                          padding: 0,
                          paddingLeft: 10,
                          paddingTop: 10,
                        }}
                      >
                        <StudentInformation
                          clickDelete={() => console.log('delete operation')}
                          show={true}
                          role={role}
                          gemsToken={item?.user?.gemsTokens || '0'}
                          onClick={() => onClick(item)}
                          selected={
                            selectedStudent === item?.user?.id ? true : false
                          }
                          studentImg={item?.user?.profileImage}
                          studentName={`@${item?.user?.username}` || 'Student'}
                          grade={item?.user?.grade_?.name}
                          schoolName={item?.user?.school?.name}
                        />
                      </ListItemButton>
                    </ListItem>
                 
                </div>
              );
            })}
          </List>
        </div>:
        <div className='text-xs font-medium text-center mb-3'>No Children added yet</div>
}
        
     
    </Box>
  );
}
export default StudentList;
