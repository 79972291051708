function VerifiedButton({ role }: { role: string }) {
  return (
    <div className="h-fit py-0.5 px-1.5 bg-[#f6e5e4] flex items-center justify-evenly rounded-full ml-2">
      <div className=" rounded-full bg-[#E50300] h-1.5 w-1.5" />
      <p className="text-[8px] font-normal ml-1 text-[#E50300]">
        {role == 'parentOnboarded' ||
        role == 'parentUpgraded' ||
        role == 'parentNotOnboarded'
          ? ' Not Verified'
          : role == 'EduNoOnbrded' ||
            role == 'EduOnboarded' ||
            role === 'eduWithData'
          ? 'Complete Setup'
          : ''}
      </p>
    </div>
  );
}

export default VerifiedButton;
