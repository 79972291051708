import { Box, Card, Typography } from '@mui/material';
import * as React from 'react';
import Header from './components/Header';
import EducatorFeature from './components/EducatorFeature';
import ToDo from './components/ToDo';
import CarouselView from './components/CarouselView';
import SkillsMain from './components/SkillsMain';
import ClassRoomComponent from './components/ClassRoomComponent';
import Strengths from './components/Strengths';
import AcademicUnlock from './components/AcademicUnlock';
import SocialMatricsList from './components/SocialMatricsList';
import ProficiencyLevels from './components/ProficiencyLevels';
import TierSupport from './components/TierSupport';
import EngagementbyTopics from './components/EngagementbyTopics';
import EngagementbyProducts from './components/EngagementbyProducts';
import TopicsEngamement from './components/TopicsEngamement';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getClassList } from 'store/Thunk/ParentEducatorDashboardThunk';
function EducatorDashboard({
  role,
  onPress,
  onClick,
}: {
  role?: any;
  onPress?: any;
  onClick?: any;
}) {
  //EduNoOnbrded //EduOnboarded
  //const [role, setRole] = React.useState('EduOnboarded');
  const [classRooms, setClassRooms] = React.useState([]);
  const [engagement, setEngagements] = React.useState<any>([]);
  const [reaction, setReactions] = React.useState({});
  const [total, setTotals] = React.useState({});
  const { classRoomList, engagements, reactions, totals } = useAppSelector(
    (state: any) => state.ParentEducatorReducer,
  );
  const { childTotals, childReactions, childEngagements } = useAppSelector(
    (state: any) => state.parentDashboardReducer,
  );
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  React.useEffect(() => {
    dispatch(getClassList());
  }, []);
  React.useEffect(() => {
    //     const userData: any = localStorage.getItem('userData');
    //     const id :any =JSON.parse(userData).id
    // console.log("id%%%%%%%%%%", id)
    //    console.log("reactions from dasboard", reactions)
    setEngagements(engagements);
    setClassRooms(classRoomList);
    setReactions(reactions);
    setTotals(totals);
  }, [classRoomList, engagements, reactions, totals]);
  React.useEffect(() => {
    //     const userData: any = localStorage.getItem('userData');
    //     const id :any =JSON.parse(userData).id
    // console.log("id%%%%%%%%%%", id)
    //    console.log("reactions from dasboard", reactions)
    setEngagements(childEngagements);
    setReactions(childReactions);
    setTotals(childTotals);
  }, [childEngagements, childReactions, childTotals]);
  const data = [
    {
      id: 1,
      img: require('../../../assets/Parent/Vector.png'),
      text: 'Start the scheduled Bellwork/DoNOW.',
    },
    {
      id: 2,
      img: require('../../../assets/Parent/Vector2.png'),
      text: 'Start the scheduled Bellwork/DoNOW.',
    },
    {
      id: 3,
      img: require('../../../assets/Parent/Vector3.png'),
      text: 'Assign your students first tasks.',
    },
  ];
  return (
    <>
      {role == 'EduNoOnbrded' ? (
        <div>
          <>
            {/** 1st div */}
            <div className=" block lg:flex w-full justify-between">
              <div className="w-full lg:w-[65%]">
                <EducatorFeature onPress={onPress} />
                <ToDo />
                <CarouselView />
                <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg my-2 px-2 pb-2 ">
                  <div className="mt-2 ml-2">
                    <Header text="Academic Metrics" />
                  </div>
                  <SkillsMain data={0} />
                </Box>
              </div>
              <div className="w-full lg:w-[35%]">
                <ProficiencyLevels />
                <TierSupport />
                <TopicsEngamement parent={false} onClick={onClick} />
              </div>
            </div>
          </>
        </div>
      ) : role === 'EduOnboarded' ? (
        <div className=" block lg:flex w-full justify-between">
          <div className="w-full lg:w-[70%]">
            <ClassRoomComponent classRooms={classRooms} role={role} />
            <ToDo edu={true} data={data} />
            <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg mt-2 p-2">
              <div>
                <Header text="Academic Metrics" />
              </div>
              <SkillsMain data={total} />
            </Box>
            <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg mt-2 p-2">
              <div className="flex items-center justify-between space-x-3">
                <div className="w-1/2">
                  <Header text="Strengths" />
                  <div className="mt-2">
                    <Strengths
                      color={''}
                      Bcolor={''}
                      strength={true}
                      count={1}
                      text={'State-aligned standard or Test Prep skill'}
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <Header text="Opportunities for Growth" />
                  <div className="mt-2">
                    <Strengths
                      color={'#FFF0F0'}
                      Bcolor={'#FF6B6B'}
                      strength={false}
                      count={1}
                      text={'State-aligned standard or Test Prep skill'}
                    />
                  </div>
                </div>
              </div>
              <AcademicUnlock />
            </Box>
            <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg mt-2 p-2">
              <SocialMatricsList data={reaction} role={role} />
            </Box>
          </div>
          <div className="w-full lg:w-[30%]">
            <ProficiencyLevels />
            <TierSupport />
            {engagement?.videoAccessments?.engagementsByTopic?.length > 0 ? (
              <div className="lg:flex block lg:mx-2 mb-2">
                <EngagementbyTopics
                  from={'edu'}
                  data={engagement?.videoAccessments?.engagementsByTopic}
                />
              </div>
            ) : (
              <TopicsEngamement parent={false} onClick={onClick} />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
export default EducatorDashboard;
