import { Typography } from '@mui/material';
import React from 'react';
import cn from 'utils/cn';

function GradientView({
  src,
  img,
  name,
  grade,
  marks,
  classGradient,
}: {
  src: any;
  img: any;
  name: string;
  grade: string;
  marks: string;
  classGradient: any;
}) {
  return (
    <div className=" flex flex-col items-center">
      <div>
        <img className="w-8  h-6" src={src} alt="" />
      </div>
      <div className="mt-2 ">
        <div
          className={cn(
            `rounded-full rounded-b-none items-center justify-center`,
            classGradient,
          )}
        >
          <img className="w-16  h-16" src={img} alt="" />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 13,
              marginTop: 2,
              textAlign: 'center',
            }}
            fontFamily="Inter"
            color="#101828"
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 11,
              //marginTop: 1,
              textAlign: 'center',
            }}
            fontFamily="Inter"
            color="#101828"
          >
            Grade: {grade}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 13,
              //marginTop: 1,

              textAlign: 'center',
            }}
            fontFamily="Inter"
            color="#101828"
          >
            {marks}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default GradientView;
