import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface IntialState {
  user: any;
}

const intialState: IntialState = {
  user: {
    type: '',
  },
};

const AuthSlice = createSlice({
  name: 'auth_slice',
  initialState: intialState,
  reducers: {
    setLoginUser: (state: Draft<IntialState>, action: PayloadAction<{}>) => {
      state.user = action.payload;
    },
    logoutUser: (state: Draft<IntialState>) => {
      state.user = null;
    },
  },
});

export const { setLoginUser, logoutUser } = AuthSlice.actions;

export const selectUser = (state: { auth: { user: any } }) => state?.auth?.user;

export default AuthSlice.reducer;
