import { Stack } from '@mui/material';
import React from 'react';
import UpgradePlan from '../componentsOld/UpgradePlan';
import StudentDetail from '../componentsOld/StudentDetail';
import ProgressTable from '../componentsOld/ProgressTable';
import Nodata from 'components/v2_components/Admin_Panel_Dashboards/skills_chart/components/noData';

export default function ReportInsight() {
  return (
    <Stack>
      <UpgradePlan />
      <StudentDetail />
      {true ? (
        <ProgressTable />
      ) : (
        <Stack mt={24} mb={10}>
          <Nodata type={2} />
        </Stack>
      )}
    </Stack>
  );
}
