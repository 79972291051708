import useModal from '../hook/useModal';

export default function CloseButton(props) {
  const {closeModal}=useModal();
  return (
    <button
      {...props}
      className="fixed top-4 right-4"
      onClick={closeModal}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
}
