import { Typography } from '@mui/material';
import React from 'react';

function Strengths({
  count,
  text,
  strength,
  color,
  Bcolor,
  value
}: {
  count: number;
  text: string;
  strength: boolean;
  color: string;
  Bcolor: string;
  value?: any;
}) {
  return (
    <div className="flex items-center space-x-1 mt-1">
      <Typography color={'#2C3E50'} sx={{ fontSize: 9, fontWeight: 600 }}>
        {count}-
      </Typography>
      <div
        className="flex items-center justify-between w-full px-2 py-1 rounded-lg"
        style={{
          borderWidth: 1,
          borderColor: strength ? '#20A58A' : Bcolor,
          backgroundColor: strength ? '#E9F6F3' : color,
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: 9 }} color={'#000000'}>
          {text}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: 10 }} color={'#000000'}>
         {value}%
        </Typography>
      </div>
    </div>
  );
}

export default Strengths;
